import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { messages } from 'src/appv2/appv2.constants';
import { DiscountDetailsInterface } from 'src/appv2/interfaces/discount-details.interface';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { SearchWriteAction } from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { CacheService } from 'src/appv2/services/cache.service';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { urlToCacheKeyMapping } from 'src/appv2/utils/constants/discount.const';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'appv2-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss',]
})
export class HomePageComponent implements OnInit, DoCheck {

  promotion: any;

  discountDetailsResponse: any;
  public discountDetails = new DiscountDetailsInterface();
  page_type: string;
  categories = []
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  page: string = 'home'
  search: HTMLInputElement
  searchType: string
  isSearchVisible: boolean
  private baseUrl = environment.url;
  visibleTrendingSearch: boolean = false
  recentSearches = []
  searchtext: string
  endIndex = 4;
  startIndex = 0;
  mainPageType: string = 'image';
  showTrendSerach: boolean = false;
  elasticSuggestions: any;
  timeout: any = null;

  /**TODO : keep Trending Photo section hidden for now. Need to improve in future */
  isTrendingSectionShow: boolean = false;

  /**TODO : keep Premium Collections section hidden for now. Need to improve in future */
  isPremiumCollectionsShow: boolean = false;

  /**
    * Open class of header component.
  */
  openClass: string = '';
  cacheKey: string;
  promotionCacheKeyName: string = 'home-page-banner';
  promotionCacheKey: string;


  constructor(private landingPage: LandingPageService, private store$: Store<any>, private route: ActivatedRoute, private http: HttpClient, private router: Router, private cacheService: CacheService, private cdr: ChangeDetectorRef) {
    this.getPageType();
    // Get Promotion Details
    this.promotionCacheKey = urlToCacheKeyMapping[this.promotionCacheKeyName];
    if (this.promotionCacheKey) {
      const promotion = this.cacheService.getData(this.promotionCacheKey);
      if (typeof promotion !== 'undefined') {
        this.promotion = promotion;
      } else {
        this.getPromotion();
      }
    }

   }

  ngOnInit(): void {
    this.mainPageType = 'image';
    // Get Discount Details
    this.cacheKey = urlToCacheKeyMapping[this.page];
    this.landingPage.isDiscountDetailsCalled.subscribe((isDiscountCalled: boolean) => {
      if (isDiscountCalled) {
        this.discountDetails = this.cacheService.getData(this.cacheKey);
      }
    })
  }

  getPageType() {
    const urlSegments = this.route.snapshot.url;
    // Get the last segment (the segment at the end of the URL)
    const lastSegment = urlSegments[urlSegments.length - 1];
    // Access the last segment's path
    const lastSegmentPath = lastSegment.path;
    // Now you can use the lastSegmentPath variable as needed
    this.page = lastSegmentPath;
    this.page_type = lastSegmentPath + '_page';
  }

  /**
   * Store keyword in elastic search api
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword
    };
    // this.loading(true);
    this.landingPage.storeKeywordInElasticSearch(bodyParams).subscribe(data => {
      this.loading(false);
    });
  }


  /**
   * Get promotion details
   */
  getPromotion() {
    this.loading(true);
    this.landingPage.getPromotionDetails(this.page_type).subscribe(data => {
      this.loading(false);
      this.promotion = data.data
      if(this.promotion){
        this.cacheService.setData(this.promotionCacheKey, this.promotion);
        this.landingPage.isHomeBannerDetailsCalled.next(true);
      }
    })
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage.getSuggestionByElasticSearch(this.searchtext).subscribe(data => {
      if (data) {
        this.elasticSuggestions = data.data
      }
    })
  }


  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.homepageLoading}))
    return
  }

  ngAfterViewInit() {
  }

  slides = [
    { data: "http://placehold.it/350x150/000000" }
  ];
  slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };

  addSlide() {
    // this.slides.push({img: "http://placehold.it/350x150/777777"})
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {

  }

  breakpoint(e) {

  }

  afterChange(e) {

  }

  beforeChange(e) {

  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.hideExcessCategories()
    this.categories = this.landingPage.getCategoriesSlider()
  }

  addToSearch(key) {
    this.search = document.querySelector('.search-form input')
    this.search.value = key;
    if (this.search.value.length === 0) return;
    this.store$.dispatch(new SearchWriteAction(<SearchState>{ searchText: this.search.value }));


    if (this.page != 'editorial') {
      this.router.navigate(['/search'], { queryParams: { type: this.searchType, search: this.search.value } });
    } else {
      this.router.navigate(['/search'], { queryParams: { type: this.searchType, search: this.search.value, product_editorial: 1 } })
    }
  }

  hideExcessCategories() {
    let categories = document.querySelectorAll('.categories__item');
    if (categories['length'] != 0) {
      let maxSize = 0;
      let count = 0;
      while (maxSize < document.querySelector('.categories__list').clientWidth && count < categories['length']) {
        (<HTMLElement>categories[count]).style.display = 'inline'
        maxSize += (<HTMLElement>categories[count]).offsetWidth;
        count++;
      }
      for (let i = count - 1; i < categories['length']; i++) {
        (<HTMLElement>categories[i]).style.display = 'none'

      }
    }

  }

  select(index) {
    this.startIndex = this.categories.findIndex(item => item === index);
  }

  next() {
    this.startIndex++;
    if (this.startIndex === this.categories.length) {
      this.startIndex = 0;
    }
  }

  prev() {
    if (this.startIndex <= 0) {
      this.startIndex = this.categories.length;
    }
    this.startIndex--;
  }
  openCategory(catName) {
    this.searchtext = catName;
    this.router.navigate(['/images'], {
      queryParams: { search: catName },
    });
    // this.router.navigate(['/categories/image',catName])
  }

}
