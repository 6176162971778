<!-- shopping cart section start -->
<section class="shopping-cart-sec">
  <div class="container">
    <div class="cart-title-row">
      <div class="cart-col-left">
        <h1 class="h1">Shopping Cart</h1>
        <ul class="breadcrumb">
          <li><a href="index.html">Home</a></li>
          <li><a href="#">Product</a></li>
          <li><span>My Cart</span></li>
        </ul>
      </div>
      <div class="cart-col-right">
        <a (click)="onSubmit()" class="thame-btn">Checkout</a>
      </div>
    </div>
    <div class="cart-dateils-row">
      <div class="cart-dateils-left"
        *ngIf="cartDataImages?.length > 0 || cartDataFootages?.length > 0 || cartDataMusics?.length > 0">
        <div class="cart-product-row" *ngFor="let image of cartDataImages">
          <div class="cart-product-left">
            <div class="product-img" style="cursor: pointer;">
              <a (click)="openItem('image',image.product)">
                <img class="img-tag" src="{{ image?.product_thumb }}" alt="product img" width="234" height="163" />
              </a>
            </div>
            <ul>
              <!-- <li>
                <a
                  (click)="
                    removeToWishlist(image.product.product_id, image.cart_id)
                  "
                  >Add to Wishlist</a
                >
              </li> -->
              <li class="like">
                <a class="btn btn-primary btn_background" (click)="showModal(image)" data-toggle="modal"
                  data-target="#likeModal">
                  Add to Wishlist
                </a>
              </li>
              <li>
                <a class="remove-pro" (click)="removeFromCart(image?.cart_id)">Remove</a>
              </li>
            </ul>
          </div>
          <div class="cart-product-right">
            <div class="product-id-row">
              <ul>
                <li>Product Id: {{ image?.product?.product_id }}</li>
                <li>
                  <!-- Small: 141X94 -->
                  Size: {{ image?.standard_size }}
                </li>
                <li>Type: {{ image?.cart_product_type }}</li>
              </ul>
              <h3 class="h3">₹{{ image?.standard_price }}</h3>
            </div>
            <h4 class="h4">{{ image?.licence?.licence_name }} Licence</h4>
            <p>Select Licence :</p>
            <select id="licence"
              (change)="onLicenceChange($event.target.value,image.standard_price,image.product.is_premium, image.product.product_main_type,image.cart_id)">
              <ng-container *ngFor="let licence of licence_options">
                <option  *ngIf="licence.product_type == 1"
                [selected]="licence.licence_name === image.licence.licence_name" [value]="licence.id">{{ licence.licence_name }}
              </option>
              </ng-container>

            </select>
            <div class="license_pop_up_inner">
              <p [innerHTML]="image?.licence?.description"></p>
            </div>

          </div>
        </div>
        <div class="cart-product-row" *ngFor="let footage of cartDataFootages">
          <div class="cart-product-left">
            <div class="product-img" style="cursor: pointer;">
              <a (click)="openItem('footage',footage.product)">
                <img src="{{ footage?.product_thumb }}" alt="" />
              </a>
            </div>
            <ul>
              <!-- <li>
                <a
                  (click)="
                    removeToWishlist(
                      footage.product.product_id,
                      footage.cart_id
                    )
                  "
                  >Add to Wishlist</a
                >
              </li> -->
              <li class="like">
                <a class="btn btn-primary" (click)="showModal(footage)" data-toggle="modal" data-target="#likeModal"
                  class="btn_background">
                  Add To Wishlist
                </a>
              </li>
              <li>
                <a class="remove-pro" (click)="removeFromCart(footage?.cart_id)">Remove</a>
              </li>
            </ul>
          </div>
          <div class="cart-product-right">
            <div class="product-id-row">
              <ul>
                <li>Product Id: {{ footage?.product?.product_id }}</li>
                <li>
                  Size:
                  <select id="resolution"
                    (change)="onVersionChange(footage.licence.slug,$event.target.value, footage?.standard_price, footage.product.is_premium,footage.cart_id)">
                    <option *ngFor="let version of footage.version_data" [value]="version.version">
                      {{ version.label }}
                    </option>
                  </select>
                </li>
                <li>Type: {{ footage?.cart_product_type }}</li>
              </ul>
              <h3 class="h3">₹{{ footage.standard_price }}</h3>
            </div>
            <h4 class="h4">{{ footage?.licence?.licence_name }} Licence</h4>
            <p>Select Licence :</p>
            <select id="licence"
              (change)="onLicenceChange($event.target.value,footage.standard_price,footage.product.is_premium, footage.product.product_main_type,footage.cart_id,footage.standard_size)">
              <ng-container *ngFor="let licence of licence_options">
              <option *ngIf="licence.product_type == 2"
                [selected]="licence.licence_name === footage.licence.licence_name" [value]="licence.id">{{ licence.licence_name }}
              </option>
              </ng-container>
            </select>
            <div class="license_pop_up_inner">
              <p>{{ footage?.licence?.description }}</p>
            </div>

          </div>
        </div>
        <div class="cart-product-row" *ngFor="let music of cartDataMusics">
          <div class="cart-product-left">
            <div class="product-img" style="cursor: pointer;">
              <a (click)="openItem('music',music.product)">
                <img src="{{ music?.product?.product_thumbnail }}" alt="Music Product" />
              </a>
            </div>
            <ul>
              <!-- <li>
                <a (click)="addToWishlist(music.product.product_id)">Add to Wishlist</a>
              </li> -->
              <li class="like">
                <a class="btn btn-primary" (click)="showModal(music)" data-toggle="modal" data-target="#likeModal"
                  class="btn_background">
                  Add To Wishlist
                </a>
              </li>
              <li>
                <a class="remove-pro" (click)="removeFromCart(music?.cart_id)">Remove</a>
              </li>
            </ul>
          </div>
          <div class="cart-product-right">
            <div class="product-id-row">
              <ul>
                <li>Product Id: {{ music?.product.product_id }}</li>
                <li>Type: {{ music?.cart_product_type }}</li>
              </ul>
              <h3 class="h3">₹{{ display_music_price !== 0 ? display_music_price : music?.total }}</h3>
            </div>
            <h4 class="h4">{{ music?.licence?.licence_name }} Licence</h4>
            <p>Select Licence :</p>
            <select id="licence"
              (change)="onLicenceChange($event.target.value,music.total,music.product.is_premium, music.product.product_main_type,music.cart_id)">
              <ng-container *ngFor="let licence of licence_options">
              <option *ngIf="licence.product_type == 3"
                [selected]="licence.licence_name === music.licence.licence_name" [value]="licence.id">{{ licence.licence_name }}
              </option>
            </ng-container>
            </select>
            <div class="license_pop_up_inner">
              <p [innerHTML] = "music?.licence?.description"></p>
            </div>

          </div>
        </div>


        <ul class="price-table">
          <li>
            <span>Subtotal</span><span class="price-right">₹{{ subTotalPrice }}</span>
          </li>
          <li>
            <span>Tax</span> <span class="price-right">₹{{ tax }}</span>
          </li>
          <li>
            <span>Grand Total</span>
            <span class="price-totle">₹{{ totalPrice }}</span>
          </li>
        </ul>
      </div>
      <div class="cart-dateils-left" *ngIf="cartLength == 0">
        <div class="no-data">
          <img src="assets/images/shopping_cart.svg" alt="like" width="200" height="200" />
          <p>Your cart is currently empty!</p>
        </div>
      </div>
      <div class="cart-dateils-right">
        <div class="billing-info-form">
          <div class="billing-info-heading">
            <h2 class="h2">Enter your billing information</h2>
          </div>
          <form [formGroup]="UserPaymentForm">
            <div class="form_group" [ngClass]="{ error_field: submitted && f.first_name.errors }">
              <label for="name">Name</label>
              <input class="form-control" type="text" placeholder="Enter your name" formControlName="first_name" />
              <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                <p *ngIf="f.first_name.errors.required" class="error">
                  * Please enter valid name
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.address.errors }">
              <label for="address">Address</label>
              <input class="form-control" type="text" placeholder="Enter your address" formControlName="address">
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <p *ngIf="f.address.errors.required" class="error">
                  * Please enter valid address
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.country.errors }">
              <label for="country">Country</label>
              <div class="custom-select-main select-show">
                <input class="form-control" type="text" placeholder="Enter your country" formControlName="country"
                  *ngIf="disabledDropCountry" readonly>
                <select class="form-control" formControlName="country" (change)="getState($event.target.value)"
                  *ngIf="!disabledDropCountry" required>
                  <option value="" disabled>Select Country</option>
                  <option *ngFor="let country of countryList" value="{{ country.id }}">
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                <p *ngIf="f.country.errors.required" class="error">
                  * Please Select Country
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.state.errors }">
              <label for="state">State</label>
              <div class="custom-select-main">
                <input class="form-control" type="text" placeholder="Enter your state" formControlName="state"
                  *ngIf="disabledDropCountry" readonly>
                <select class="form-control" formControlName="state" (change)="getCity($event.target.value)"
                  *ngIf="!disabledDropCountry" placeholder="Select state">
                  <option disabled value="">Select State</option>
                  <option *ngFor="let region of stateList" value="{{ region.id }}">
                    {{ region.state }}
                  </option>
                </select>
              </div>
              <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <p *ngIf="f.state.errors.required" class="error">
                  * Please Select State
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.city.errors }">
              <label for="city">City</label>
              <div class="custom-select-main">
                <select class="form-control" formControlName="city">
                  <option disabled value="">Select City</option>
                  <option *ngFor="let city of cityList" value="{{ city.id }}">
                    {{ city.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                <p *ngIf="f.city.errors.required" class="error">
                  * Please Select City
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.pincode.errors }">
              <label for="password">Postal Code</label>
              <input class="form-control" type="tel:" placeholder="Enter Postal Code" formControlName="pincode" />
              <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                <p *ngIf="f.pincode.errors.required" class="error">
                  * Please enter Postal Code
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.company_name.errors }">
              <label for="company_name">Company Name</label>
              <input class="form-control" type="text" placeholder="Company Name" formControlName="company_name" />
              <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                <p *ngIf="f.company_name.errors.required" class="error">
                  * Please enter company came
                </p>
              </div>
            </div>
            <div class="form_group" [ngClass]="{ error_field: submitted && f.gst.errors }">
              <label for="number">Company GST Number</label>
              <input class="form-control" type="text" placeholder="Company Gst Number" formControlName="gst" />
              <div *ngIf="submitted && f.gst.errors" class="invalid-feedback">
                <p *ngIf="f.gst.errors.required" class="error">
                  * Please enter GST number
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<add-to-wishlist [productData]="wishlistData" (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal">
</add-to-wishlist>
<!-- select box js -->
