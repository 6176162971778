import { Component, EventEmitter, Input, OnInit, Output,ChangeDetectionStrategy } from '@angular/core';




interface NumberedPagination {
  currentPage: number;
  maxPages: number;
  pages: number[];
}

enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT',
}
@Component({
  selector: 'appv2-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {

  ngOnInit(): void {
    // this.maxPages = Math.ceil(this.totalPages / this.pageSize);
    this.maxPages = this.totalPages;
  }

  // Input properties
  maxPages: number = 0;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  @Input() totalPages: number;

  @Output() pageChanged = new EventEmitter<number>();


  @Input() pageSize: number = 3;
  @Input() rulerLength: number = 3;

  constructor() {


  }

  get pagination(): NumberedPagination {
    const { currentPage, maxPages, rulerLength, totalPages } = this;

    let pages: number[] = [];

    if (totalPages <= 3) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      pages = ruler(currentPage, maxPages, rulerLength);
    }

    return { currentPage, maxPages, pages } as NumberedPagination;
  }

  navigateToPage(pageNumber: number): void {
    if (pageNumber < 1 || pageNumber > this.totalPages) return;

    if (allowNavigation(pageNumber, this.currentPage, this.totalPages)) {
      this.currentPage = pageNumber;
      this.pageChanged.emit(this.currentPage);
    }
  }

  trackByFn(currentPage: number): number {
    return currentPage;
  }
}

const ruler = (
  currentIndex: number,
  maxPages: number,
  rulerLength: number
): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, currentPage) =>
    rulerFactory(currentIndex, currentPage, min, maxPages, rulerLength)
  ).filter(page => page >= 1 && page <= maxPages);
};

const rulerOption = (
  currentIndex: number,
  min: number,
  maxPages: number
): RulerFactoryOption => {
  return currentIndex <= min
    ? RulerFactoryOption.Start
    : currentIndex >= maxPages - min
    ? RulerFactoryOption.End
    : RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  currentPage: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => Math.min(currentPage + 1, maxPages),
    [RulerFactoryOption.End]: () => Math.min(maxPages - rulerLength + currentPage + 1, maxPages),
    [RulerFactoryOption.Default]: () => Math.min(currentIndex + currentPage - min, maxPages),
  };

  return factory[rulerOption(currentIndex, min, maxPages)]();
};

const allowNavigation = (
  pageNumber: number,
  currentPage: number,
  maxPages: number
): boolean => {
  return pageNumber !== currentPage && pageNumber > 0 && pageNumber <= maxPages;
};

