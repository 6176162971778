<div class="pagination" *ngIf="totalPages > 1">
  <ol class="paginator-container">
    <!-- Previous Button -->
    <li class="pagination-previous" *ngIf="currentPage > 1" (click)="navigateToPage(currentPage - 1)" style="font-size: 25px;">
      <i class="fas fa-angle-left"></i>
    </li>

    <!-- Page Numbers -->
    <li
      *ngFor="let page of pagination.pages; trackBy: trackByFn"
      class="paginator-number"
      [class.active]="page === pagination.currentPage"
      (click)="navigateToPage(page)"
    >
      {{ page }}
    </li>

    <!-- Dots only if there are more pages ahead -->
    <li *ngIf="pagination.pages[pagination.pages.length - 1] < totalPages">...</li>

    <!-- Next Button -->
    <li class="pagination-next" *ngIf="currentPage < totalPages" (click)="navigateToPage(currentPage + 1)" style="font-size: 25px;">
      <i class="fas fa-angle-right"></i>
    </li>
  </ol>
</div>

