import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Media } from 'ng-opengallery';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import {
  SearchWriteAction,
  SearchchangeSearchName,
} from 'src/appv2/reducers/search/search.actions';
import { SearchState } from 'src/appv2/reducers/search/search.models';
import { LandingPageService } from 'src/appv2/services/landing-page.service';
import { SearchService } from 'src/appv2/services/search.service';
import { FilterComponent } from '../filter/filter.component';

@Component({
  selector: 'appv2-image-listing',
  templateUrl: './image-listing.component.html',
  styleUrls: ['./image-listing.component.scss'],
})
export class ImageListingComponent implements OnInit {
  receivedProductCount: string;

  receiveDataFromChild(data: string) {
    this.receivedProductCount = data;
  }

  /**
   * Open class of header component.
   */
  filters;
  showReset = false;
  openClass: string = '';
  searchKey: string = '';
  visibleTrendingSearch: boolean;
  showTrendSerach: boolean = false;
  mainPageType: string = 'image';
  recentSearches = [];
  page_type: string;
  elasticSuggestions: any;
  trendingKeyword: any;
  searchtext: string = '';
  timeout: any = null;
  selectedOption = 'Popular';
  dropdownOpen = false;
  galleryData: Array<any>;
  itemDataFromSorting: Array<any>;
  totalRecords: Number;
  totalFromSorting: Number;
  options = [ 'Popular', 'Recent', 'Price: Low to High', 'Price: High to Low'];
  processedFilters: { key: string; value: string; hasMultipleValue: string; isGroupValue: string }[] =
    [];
  fileExtension: string;
  recordsPerPage: number;
  itemsPerPage = Number;
  recordsFromSorting = Number;
  @ViewChild(FilterComponent) childComponentReference: FilterComponent;
  shouldDisplayUl: boolean = false;
  fromCategory:Boolean
  public productType:string = ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private landingPage: LandingPageService,
    private mysearch: SearchService,
    private location: Location,
    private activatedRouter: ActivatedRoute,
    private store$: Store<any>
  ) {
    this.route.queryParams.subscribe((params) => {
      this.searchtext = params['search'];
      this.store$.dispatch(
        new SearchchangeSearchName(<SearchState>{
          searchType: this.mainPageType,
        })
      );
    });

    let search = this?.activatedRouter?.snapshot?.queryParamMap?.get('search');
    if (typeof search !== 'undefined' && search != '') {
      this.searchKey = search;
    }
    this.fromCategory = false
  }

  ngOnInit(): void {
    this.productType = 'image'
    this.recentSearches = JSON.parse(localStorage.getItem('recent_search'));
  }
  getFilterData(filterData: any) {
    const processedFilters: {
      key: string;
      value: string;
      namekey: string;
      valuekey: string;
      hasMultipleValue: string;
      isGroupValue: string;
    }[] = [];

    for (const key in filterData) {
      if (
        filterData.hasOwnProperty(key) &&
        key.endsWith('_name') &&
        Array.isArray(filterData[key])
      ) {
        const filterName = key.replace('_name', '');
        const filterValues = filterData[key].join(', ');

        const filterShowName = filterName + '_filtername';
        const filterOptionName = filterName + '_optionname';
        const hasMultipleValue = filterName + '_hasMultipleValues';
        const isGroupValue = filterName + '_isGroupValues';
        const nameValue = filterData[filterShowName][0];

        const optionValues = filterData[filterOptionName].join(', ');
        const hasMultipleValuedata = filterData[hasMultipleValue][0];
        //const isGroupValuedata = filterData[isGroupValue].join(', ');
        let isGroupValuedata = '';
        if (filterData[isGroupValue][0] != null) {
          isGroupValuedata = filterData[isGroupValue].join(', ');
        }

        processedFilters.push({
          key: filterName,
          value: filterValues,
          namekey: nameValue,
          valuekey: optionValues,
          hasMultipleValue: hasMultipleValuedata,
          isGroupValue: isGroupValuedata,
        });
      }
    }

    this.processedFilters = processedFilters;

    const hasNonEmptyValue = this.processedFilters.some(filter => filter.value !== undefined && filter.value.trim() !== '');
    this.showReset = hasNonEmptyValue;

    this.processedFilters = this.processedFilters.filter(
      (filter) => filter.value !== ''
    );

    this.shouldDisplayUl = this.processedFilters.some(
      (filter) => filter.value !== ''
    );

    const navigationExtras: NavigationExtras = {
      queryParams: { search: this.searchtext },
    };

    if (this.searchtext == undefined || this.searchtext == '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { search: ' ' },
      };
      this.router.navigate(['/images'], navigationExtras);
      this.router.navigate(['/images']);
    } else {
      this.router.navigate(['/images']);
      this.router.navigate(['/images'], navigationExtras);
    }
  }

  removeSpecificFilter(filter_type,index) {
    this.childComponentReference.removeFilters(filter_type,index);
    this.childComponentReference.checkboxChange.emit(false);
  }

  resetFilter() {
    this.childComponentReference.resetFilter();
  }

  /**
   * Loading image listing component
   * @param status
   */
  public loading(status: boolean) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: status })
    );
    return;
  }

  /**
   * Store keyword in elastic search API
   * @param keyword
   */
  storeKeywordInElasticSearch(keyword) {
    const bodyParams = {
      search_field: keyword,
    };
    // this.loading(true);
    this.landingPage
      .storeKeywordInElasticSearch(bodyParams)
      .subscribe((data) => {
        this.loading(false);
      });
  }

  onMouseInSearchBox() {
    this.visibleTrendingSearch = true;
  }

  onMouseOutSearchBox() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
  }

  /**
   * Determines whether key press on
   * @param event
   */
  onKeyPress(event: any) {
    clearTimeout(this.timeout);
    this.openClass = '';
    this.timeout = setTimeout(() => {
      this.searchtext = event.target.value;
      if (this.searchtext && this.searchtext.trim().length != 0) {
        this.getSuggestionByElasticSearch(this.searchtext);
        this.showTrendSerach = true;
        this.visibleTrendingSearch = false;
      } else {
        this.showTrendSerach = false;
        this.visibleTrendingSearch = true;
      }
    }, 1000);
    if (event.keyCode == 13) {
      this.showTrendSerach = false;
      this.storeKeywordInElasticSearch(this.searchtext);
      this.onEnterSearch();
    }
  }

  /**
   * Gets suggestion keyword list by elastic search
   * @param searchText
   */
  getSuggestionByElasticSearch(searchText) {
    this.searchtext = searchText;
    this.landingPage
      .getSuggestionByElasticSearch(this.searchtext)
      .subscribe((data) => {
        if (data) {
          this.elasticSuggestions = data.data;
        }
      });
  }

  public onInput(event: any) {
    const inputValue = event.target.value;
    // Define a regular expression to match letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(inputValue)) {
      // If the input contains characters other than letters, numbers, and spaces,
      // remove those characters from the input value
      event.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    }
  }

  onEnterSearch() {
    // save to local storage
    let recentSearchData = JSON.parse(localStorage.getItem('recent_search'));
    this.recentSearches = recentSearchData;
    if (this.searchtext) {
      if (!recentSearchData) {
        recentSearchData = [];
      }
      if (recentSearchData.length > 4) {
        recentSearchData.shift();
      }
      if (this.searchtext) {
        recentSearchData.push(this.searchtext);
      }
      localStorage.setItem('recent_search', JSON.stringify(recentSearchData));
      if (this.page_type == 'image' || this.mainPageType == 'image') {
        const queryParams = { search: this.searchtext };
        const navigationExtras: NavigationExtras = {
          queryParams: queryParams,
        };
        this.router.navigate(['/images'], navigationExtras);
      } else {
        this.openCategory(this.searchtext);
      }
    } else {
      const queryParams = { search: this.searchtext };
      const navigationExtras: NavigationExtras = {
        queryParams: queryParams,
      };
      this.router.navigate(['/images']);
    }
  }

  clickOpenMenu() {
    this.visibleTrendingSearch = false;
    this.showTrendSerach = false;
    if (this.openClass === 'open') {
      this.openClass = '';
    } else {
      this.openClass = 'open';
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.dropdownOpen = false;

    this.filters = {
      type: 'image',
      product_editorial: 0,
      sort: this.selectedOption,
    };

    if (this.searchtext == undefined || this.searchtext == '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { search: ' ' },
      };
      this.router.navigate(['/images'], navigationExtras);
      this.router.navigate(['/images']);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: { search: this.searchtext },
      };
      this.router.navigate(['/images']);
      this.router.navigate(['/images'], navigationExtras);
    }
  }

  reloadComponent() {
    this.location.replaceState(this.location.path());
  }

  /**
   * Open category.
   * @param catName
   */
  openCategory(catName) {
    this.searchtext = catName;
    this.store$.dispatch(
      new SearchWriteAction(<SearchState>{ searchText: 'category ' + catName })
    );
  }
}
