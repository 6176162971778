<!-- footage detail page information section start -->
<section class="footage_detailpage_information_section"  *ngIf="!invalidData">
  <div class="container">
    <div class="footage_info">
      <div class="footage_info_image_section">
        <div class="footage_image">
          <picture>
            <source
              srcset="assets/images/footage_car.webp"
              alt="footage-car-image"
              width="881"
              height="651"
            />
            <source
              srcset="assets/images/footage_car.jpg"
              alt="footage-car-image"
              width="881"
              height="651"
            />
            <!-- <img src="assets/images/footage_car.jpg" alt="footage-car-image" width="881" height="651" /> -->
            <video
              class="img-tag"
              #videoElement
              src="{{ itemData?.product_main_image }}"
              width="835"
              height="556"
              autoplay
            ></video>
          </picture>
        </div>
        <div class="footage_image_content">
          <div class="footage_image_content_heading">
            <h4 class="h4">Description</h4>
            <p>{{ itemData?.product_description }}</p>
          </div>
          <div class="footage_image_keywords">
            <h4 class="h4">Keywords:</h4>
            <div class="keywords">
              <div
                class="keywords_btn"
                *ngFor="let item of itemData?.product_keywords?.split(',') | slice:0:10"
              >
                <a id="key_{{ item }}" (click)="onClickKeyword(item)">{{
                  item.length > 9 ? item.slice(0, 9 - 1) + "..." : item
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footage_info_contact_section">
        <form
          class="col-md-5 buy-info"
          [formGroup]="AddToCartForm"
          (ngSubmit)="onSubmit()"
          *ngIf="this.AddToCartForm"
        >
          <div class="footage_info_heading">
            <div *ngIf="!isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
              <h2 class="h2">{{ truncatedTitle }}...</h2>
            </div>
            <div *ngIf="isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
              <h2 class="h2">{{ itemData?.product_title }}</h2>
            </div>
            <p>Credit: {{itemData?.author_username}} | Product ID: {{ itemData?.product_id }}</p>
            <i class="fa fa-info-circle" aria-hidden="true" *ngIf="itemData?.editorial ==1"></i>&nbsp;<span class="h3" *ngIf="itemData?.editorial ==1">This is editorial product</span>
          </div>
          <div class="footage_info_licence_type">
            <h3 class="h3">Licence type</h3>
            <div class="footage_licence" >
              <div class="licence_type" *ngFor="let licence of licenceData; let i = index;" >
                <input
                  type="radio"
                  name="radio-group"
                  aria-label="radio-btn"
                  [checked]="i == licenceCheck"
                  [formControlName]="choosenLicense"
                  name="choosenLicense"
                  value="{{licence.id}}"
                  (click)="hideShowRF(licence.licence_name,licence.id)"
                />
                <label for="radio-group">
                  <span class="licence_name">{{licence.licence_name}}</span>
                  <span class="info_image full_rf_license"
                  (click)="handleInfoPopup(licence)"
                    ><img
                      src="assets/images/info.svg"
                      alt="info"
                      width="16"
                      height="16"
                      class="rf-img"
                  /></span>
                </label>
                <div class="full_rf_license_pop_up" *ngIf="showFullRF &&  selectedLicense?.id === licence.id">
                  <div class="license_pop_up_inner">
                    <h4 class="h4">{{selectedLicense.licence_name}}</h4>
                    <p>{{selectedLicense.description}}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--TODO: Comment as per ticket IF-243-->
          <!-- <div class="upgrade_to_editorial_footages">
            <a href="javascript:void(0)">Upgrade to Editorial Footages</a>
          </div> -->
          <div class="price" *ngIf="filterVersion.length >0">
            <h3 class="h3">Price</h3>
            <div class="price_box_inner" >
              <ng-container *ngFor="let version of filterVersion;let i = index;">
                <a
                  class="price_box"
                  *ngIf="version.label != 'Large'"
                  id="{{ version?.label }}"
                >
                <input
                *ngIf="itemData?.video"
                type="radio"
                [formControlName]="choosenItem"
                name="choosenItem"
                value="{&quot;version&quot;: &quot;{{
                  version.label
                }}&quot;,&quot;price&quot;: &quot;{{
                  version.price
                }}&quot;,&quot;is_premium&quot;: &quot;{{
                  version.is_premium
                }}&quot;}"
                id="version_{{ version?.label }}"
                attr ="{{version?.label}}"
                [checked]="i == priceCheck"
                (change)="changePrice($event, i)"
              />
                  <span *ngIf="itemData?.video" class="rupees"
                    >₹{{ version?.price }}</span
                  >
                  <span *ngIf="itemData?.video" class="minutes">{{
                    version?.label
                  }}</span>
                </a>
              </ng-container>
            </div>
          </div>
          <div class="footage_info_btn">
            <button *ngIf="userLogined && displayCart && !disabled" type="submit" class="add_to_cart">
              Add To Cart
            </button>
            <a
              routerLink="/signin"
              class="add_to_cart"
              type="button"
              *ngIf="!userLogined && !disabled"
              >Add to Cart</a
            >
            <a
              class="add_to_cart"
              type="button"
              *ngIf="disabled"
              aria-disabled="true"
              style="cursor: not-allowed;"
              >Add to Cart</a
            >
            <a
              *ngIf="
                !getAlreadyDownloadedImageFlag &&  displayDownload && !disabled
              "
              class="btn btn-primary download"
              type="button"
              data-toggle="modal"
              (click)="openSelectedPlans()"
              >Download</a
            >
            <a
            *ngIf="
              getAlreadyDownloadedImageFlag &&  displayDownload && !disabled
            "
            class="btn btn-primary download"
            type="button"
            data-toggle="modal"
            (click)="redownloadProduct()"
            > Re Download</a
          >

          </div>
          <div class="cart_pop_up" *ngIf="showCartPopup">
            <div class="cart_top">
              <h4 class="h4">Item Added To a Cart</h4>
              <!-- <span class="add_items">1/1</span> -->
            </div>
            {{ addedToCartData }}
            <div class="cart_middle">
              <div class="pop_up_image">
                <!-- <picture>
                                <source srcset="assets/images/footage_cart.webp" alt="cart-popup-image" width="339" height="140" />
                                <source srcset="assets/images/footage_cart.jpg" alt="cart-popup-image" width="339" height="140" />

                                <video
                                class="img-tag"
                                #videoElement

                                src="{{ itemData?.cartInfo?.product?.product_info[0].flv_base }}"

                                autoplay
                                ></video>


                            </picture> -->
                <img
                  src="{{
                    itemData?.product_thumbnail
                  }}"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
              </div>
              <div class="plan_details">
                <div class="plan_details_left">
                  <h4 class="h4">
                    {{
                      itemData?.product_title.length > 10
                        ? itemData?.product_title.slice(
                            0,
                            10
                          ) + "..."
                        : itemData?.product_title
                    }}
                  </h4>
                  <p>SD | 30 FPS</p>
                </div>
                <div class="plan_details_right">
                  <span class="value"
                    >₹{{
                      selectedPrice?.price
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="cart_bottom">
              <h4 class="h4">
                Cart Subtotal: ₹{{
                  selectedPrice?.price
                }}
              </h4>
              <div class="checkout">
                <a routerLink="/payment-method">Checkout</a>
              </div>
              <div class="view_cart">
                <a routerLink="/cart">View My Cart</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="similar_footage_section" *ngIf="relatedData?.length > 0 &&  !invalidData">
  <div class="container">
    <div class="similar_footage_inner">
      <h2 class="h2">Similar Footages</h2>
      <div class="similar_footage_row">
        <div
          class="similar_footage_image"
          *ngFor="let item of relatedData | slice : 0 : 10; let i = index"
        >
          <picture  (mouseenter)="playVideo(item)" (mouseleave)="resetVideo(item)">
              <img
              *ngIf="!item.videoLoaded"
                  class="img-tag"
                  src="{{item.product_thumbnail}}"
                  alt="list img"
                  width="457"
                  height="265"
              />
              <video
              *ngIf="item.videoLoaded"
                class="img-tag"
                #videoElement
                src="{{ item.product_main_image}}"
                width="457"
                height="265"
                autoplay
                (click)="openItem(item?.slug)"
                style="cursor: pointer;"
              ></video>
            </picture>
          <div class="hd_resolution">
            <a href="javascript:void(0)">
              <span class="resolution">{{ item?.options?.label }}</span>
            </a>
          </div>
          <!-- <div class="rupees">₹{{ item?.product_price }}</div> -->

          <div class="like">
            <a class="btn btn-primary"
            (click)="showModal(item)"
            data-toggle="modal"
            data-target="#likeModal"
            class="btn_background"
            style="cursor: pointer;">
              <img
              *ngIf="!userWishlistedData.includes(item?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
              *ngIf="userWishlistedData.includes(item?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="footage_same_author_section" *ngIf="relatedAuthorData.length > 0 &&  !invalidData">
  <div class="container">
    <div class="footage_same_author_inner">
      <h2 class="h2">Footages from same author</h2>
      <div class="footage_same_author_row">
        <div
          class="footage_same_author_image"
          *ngFor="let item of relatedAuthorData | slice : 0 : 10; let i = index"
        >
        <picture  (mouseenter)="playVideo(item)" (mouseleave)="resetVideo(item)">
            <img
            *ngIf="!item.videoLoaded"
                class="img-tag"
                src="{{item.product_thumbnail}}"
                alt="list img"
                width="457"
                height="265"
            />
            <video
            *ngIf="item.videoLoaded"
              class="img-tag"
              #videoElement
              src="{{ item.product_main_image}}"
              width="457"
              height="265"
              autoplay
              (click)="openItem(item?.slug)"
              style="cursor: pointer;"
            ></video>
          </picture>
          <div class="hd_resolution">
            <a href="javascript:void(0)">
              <span class="resolution">{{ item?.product_label }}</span>
            </a>
          </div>
          <!-- <div class="rupees">₹{{ item?.options?.price }}</div> -->
          <div class="image_name">
            <a (click)="openItem(item?.slug)">{{ item?.product_title }}</a>
          </div>
          <div class="like">
            <a
              class="btn btn-primary"
              (click)="showModal(item)"
              data-toggle="modal"
              data-target="#likeModal"
              class="btn_background"
              style="cursor: pointer;"
            >
              <img
              *ngIf="!userWishlistedData.includes(item?.product_thumbnail)"
                src="assets/images/heart-icon.svg"
                alt="like"
                width="24"
                height="24"
                class="white_heart"
              />
              <img
              *ngIf="userWishlistedData.includes(item?.product_thumbnail)"
                src="assets/images/red_heart.svg"
                alt="red_heart"
                width="24"
                height="24"
                class="red_heart"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<add-to-wishlist
  [productData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>

<!-- User Plan list Modal start -->
<user-plan-modal
  [userSubscriptionPlans]="userSubscriptionPlans"
  (dataEvent)="handleUserPlanModalEvent($event)"
  [licenseId] = "AddToCartForm.value['choosenLicense']"
  [versionId] = "selectedPrice?.version == '4K' ? 2 : 1"
  *ngIf="showUserPlans"
>
</user-plan-modal>

<!--For Invali Data-->
<appv2-static-product-data [type] = "3" *ngIf="invalidData"></appv2-static-product-data>
