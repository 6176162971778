<section class="music_information_section"  *ngIf="!invalidData">
  <div class="container">
    <div class="music_info">
      <div class="music_info_video_section">
        <div class="music_video"></div>
        <div class="music_video_content">
          <div class="music_video_content_heading">
            <img
              src="assets/img/music-img.png"
              alt="info"
              width="882"
              height="447"
            />
            <audio  #audioPlayer class="playback" src="{{ musicData?.product_main_image }}" (playing)="play()" (pause)="pause()" controls="controls" preload="none"
              controlsList="nodownload" style="width:100%"></audio>
            <h4 class="h4">Description</h4>
            <p>
              {{
                musicData["product_description"]?.length > 0
                  ? musicData["product_description"]
                  : ""
              }}
            </p>
          </div>
          <div class="music_video_description">
            <div class="description_inner">
              <h4 class="h4">Publisher</h4>
              <span>{{

                   musicData?.author_username

              }}</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">Licence</h4>
              <span>{{
                musicData?.licence_type?.length > 0
                  ? musicData?.license_type
                  : ""
              }}</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">P.R.O Track</h4>
              <span>No</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">Sample Rate</h4>
              <span>{{

                   musicData?.music_price

              }}</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">Bytes/sec</h4>
              <span>{{

                   musicData?.music_duration

              }}</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">Bits per Sample</h4>
              <span>{{

                   musicData?.music_sound_bpm

              }}</span>
            </div>
            <div class="description_inner">
              <h4 class="h4">Loops</h4>
              <span>No</span>
            </div>
          </div>
          <div class="music_video_keywords">
            <h4 class="h4">Keywords:</h4>
            <div class="keywords">
              <span *ngFor="let musicKeyword of musicKeywords |slice:0:10; let i = index ">
                <div class="keywords_btn">
                  <a (click)="onClickKeyword(musicKeyword)">{{ musicKeyword }}</a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="music_info_content_section">

        <form class="col-md-5 buy-info" [formGroup]="AddToCartForm" (ngSubmit)="onSubmit()" *ngIf="this.AddToCartForm">
        <div class="music_info_heading">
          <div *ngIf="!isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
            <h2 class="h2">{{ truncatedTitle }}...</h2>
          </div>
          <div *ngIf="isFullTitleDisplayed" (click)="toggleTitle()" style="cursor: pointer;">
            <h2 class="h2">{{ musicData?.product_title }}</h2>
          </div>
          <i class="fa fa-info-circle" aria-hidden="true" *ngIf="musicData.editorial ==1"></i>&nbsp;<span class="h3" *ngIf="musicData.editorial ==1">This is editorial product</span>
          <p>
            Credit: {{musicData?.author_username}} | Product ID:
            {{

                 musicData?.product_id

            }}
            |
            {{
              musicData.music_sound_bpm?.length > 0
                ? musicData?.music_sound_bpm
                : "0"
            }}
            BPM |
            {{

                 musicData?.music_fileType
             }}
            |
            {{

                 musicData?.music_size

            }}
          </p>
        </div>
        <div class="music_info_licence_type">
          <h3 class="h3">Licence type</h3>
          <div class="footage_licence" >
          <div class="licence_type" *ngFor="let licence of licenceData; let i = index;" >
            <input
              type="radio"
              name="radio-group"
              aria-label="radio-btn"
              [checked]="i == licenceCheck"
              [formControlName]="choosenLicense"
              name="choosenLicense"
              value="{{licence.id}}"
              (click)="hideShowExtended(licence.licence_name,licence.id)"
            />
            <label for="radio-group">
              <span class="licence_name">{{licence.licence_name}}</span>
              <span class="info_image extended-license"
              (click)="hideShowExtendedInfo(licence)"
                ><img
                  src="assets/images/info.svg"
                  alt="info"
                  width="16"
                  height="16"
                  class="extended-img"
              /></span>
            </label>
            <div class="extended-license_pop_up" *ngIf="extendedInfo && selectedLicense?.id == licence.id" [innerHTML]="selectedLicense?.description">
            </div>
          </div>
        </div>
        </div>
        <div class="price" *ngIf="displayCart">
          <h3 class="h3">Price</h3>
          <div class="price_box_inner" *ngIf="filterVersion.length >0">
            <ng-container *ngFor="let version of filterVersion;let i = index;">
              <a
                class="price_box"
                *ngIf="version.label != 'Large'"
                id="{{ version?.version }}"
              >
              <input

              type="radio"
              [formControlName]="choosenItem"
              name="choosenItem"
              value="{&quot;label&quot;: &quot;{{
                version.label
              }}&quot;,&quot;price&quot;: &quot;{{
                version.price
              }}&quot;}"
              id="version_{{ version?.label }}"
              [checked]="i == 0"
              (change)="changePrice($event, i)"
            />
                <span  class="rupees"
                  >₹{{ version?.price }}</span
                >
                <span  class="minutes">{{
                  version?.label
                }}</span>

              </a>
            </ng-container>
          </div>
        </div>
        <div class="music_info_btn">
          <button *ngIf="userLogined && displayCart" type="submit" class="add_to_cart">
            Add To Cart
          </button>
          <a
              routerLink="/signin"
              class="add_to_cart"
              type="button"zcxd
              *ngIf="!userLogined"
              >Add to Cart</a
            >
          <a
          *ngIf="!getAlreadyDownloadedMusicFlag &&  displayDownload"
            type="button"
            data-toggle="modal"
            class="btn btn-primary download"
            (click)="openSelectedPlans()"
            >Download
          </a>
          <a
          *ngIf="getAlreadyDownloadedMusicFlag && displayDownload"
            type="button"
            data-toggle="modal"
            class="btn btn-primary download"
            (click)="redownloadProduct('')"

            > Re Download
          </a>
        </div>
        <div class="cart_pop_up" *ngIf="showCartPopup">
          <div class="cart_top">
            <h4 class="h4">Item Added To a Cart</h4>
            <span class="add_items">1/1</span>
          </div>
          <div class="cart_middle">
            <div class="pop_up_image">
              <picture>
                <source
                  srcset="assets/images/cart_pop_up.webp"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
                <source
                  srcset="assets/images/cart_pop_up.png"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
                <img
                  src="assets/images/cart_pop_up.png"
                  alt="cart-popup-image"
                  width="339"
                  height="140"
                />
              </picture>
            </div>
            <div class="plan_details">
              <div class="plan_details_left">
                <h4 class="h4">
                  {{

                    (musicData?.product_title.length>10)? (musicData?.product_title | slice:0:10)+'...':(musicData?.product_title)

                  }}
                </h4>
                <p>
                  {{

                       musicData?.music_duration

                  }}
                  |
                  {{
                    musicData?.music_sound_bpm?.length > 0
                      ? musicData?.music_sound_bpm
                      : "0"
                  }}
                  BPM
                </p>
              </div>
              <div class="plan_details_right">
                <span class="value"
                  >₹{{filterVersion.length > 0 ? filterVersion[0].price : ""

                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="cart_bottom">
            <h4 class="h4">
              Cart Subtotal: ₹{{
                musicData?.music_price?.length > 0
                  ? musicData?.music_price
                  : ""
              }}
            </h4>
            <div class="checkout">
              <a routerLink="/payment-method">Checkout</a>
            </div>
            <div class="view_cart">
              <a routerLink="/cart">View My Cart</a>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="similar_tracks" *ngIf="similarTracks?.length > 0 &&  !invalidData">
  <div class="container">
    <h2 class="h2">Similar Tracks</h2>
    <div class="music_table">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Duration</th>
            <th>BPM</th>
            <th>Format</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trackmusic of similarTracks; let i = index">
            <td>
              <a
                *ngIf="trackmusic['product_main_image']"
                href="javascript:void(0)"
                (click)="handleClick(i,'similar')"
                class="play_btn"
                ><img
                  [src]="
                    i == currentIndex && isPlaying
                      ? 'assets/images/circle-pause.svg'
                      : 'assets/images/play.svg'
                  "
                  alt="play-button"
                  width="30"
                  height="30"
                  class="play-btn"
              /></a>
              <span (click)="redirectToDetailsPage(trackmusic['slug'])" [ngStyle]="{'cursor': 'pointer' }">{{ trackmusic["product_title"] }}</span>
            </td>
            <td>{{ trackmusic["random_three_keywords"] }}</td>
            <td>{{ trackmusic["music_duration"] }}</td>
            <td>{{ trackmusic["music_sound_bpm"] }}</td>
            <td>{{ trackmusic["music_fileType"] }}</td>
            <td class="music_icon" [ngStyle]="{ cursor: 'pointer' }">
              <li class="like">
                <a
                  class="btn btn-primary"
                  (click)="showModal(trackmusic)"
                  data-toggle="modal"
                  data-target="#likeModal"
                  class="btn_background"
                  style="cursor: pointer;"
                >
                <img *ngIf="!userWishlistedData.includes(trackmusic['product_thumbnail'])" src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart" />
                <img *ngIf="userWishlistedData.includes(trackmusic['product_thumbnail'])" src="assets/images/black_heart.svg" alt="red_heart" width="24" height="24" class="black_heart"/>
                </a>
              </li>
              <div class="music_icon_image">

                <a routerLink="/music/{{trackmusic['slug']}}" *ngIf="!displayDownload"
                  ><img
                    src="assets/images/shopping_cart.svg"
                    alt="like"
                    width="24"
                    height="24"
                /></a>
                <a routerLink="/music/{{trackmusic['slug']}}" *ngIf="displayDownload"
                  ><img
                    src="assets/images/download_plan.svg"
                    alt="like"
                    width="24"
                    height="24"
                /></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<section class="same_author_section" *ngIf="isSameAuthorSectionShow &&  !invalidData">
  <div class="container">
    <h2 class="h2">Track from Same Author</h2>
    <div class="music_table">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Duration</th>
            <th>BPM</th>
            <th>Format</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let authermusic of similarTracksAuthors; let i = index">
            <td>
              <a
                *ngIf="authermusic['product_main_image']"
                href="javascript:void(0)"
                (click)="handleClick(i,'author')"
                class="play_btn"
                ><img
                  [src]="
                    i == currentIndex && isPlaying
                      ? 'assets/images/circle-pause.svg'
                      : 'assets/images/play.svg'
                  "
                  alt="play-button"
                  width="30"
                  height="30"
                  class="play-btn"
              /></a>
              <span>{{ authermusic["product_title"] }}</span>
            </td>
            <td>{{ authermusic["random_three_keywords"] }}</td>
            <td>{{ authermusic["music_duration"] }}</td>
            <td>{{ authermusic["music_sound_bpm"] }}</td>
            <td>{{ authermusic["music_fileType"] }}</td>
            <td class="music_icon" [ngStyle]="{ cursor: 'pointer' }">
              <li class="like">
                <a
                  class="btn btn-primary"
                  (click)="showModal(authermusic)"
                  data-toggle="modal"
                  data-target="#likeModal"
                  class="btn_background"
                >
                <img *ngIf="!userWishlistedData.includes(authermusic['product_thumbnail'])" src="assets/images/heart-icon.svg" alt="like" width="24" height="24" class="white_heart" />
                <img *ngIf="userWishlistedData.includes(authermusic['product_thumbnail'])" src="assets/images/black_heart.svg" alt="red_heart" width="24" height="24" class="black_heart"/>
                </a>
              </li>
              <div class="music_icon_image">
                <a routerLink="/music/{{authermusic['slug']}}" *ngIf="!displayDownload"
                ><img
                  src="assets/images/shopping_cart.svg"
                  alt="like"
                  width="24"
                  height="24"
              /></a>
              <a routerLink="/music/{{authermusic['slug']}}" *ngIf="displayDownload"
                ><img
                  src="assets/images/download_plan.svg"
                  alt="like"
                  width="24"
                  height="24"
              /></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
<!-- Add To Wishlist Modal -->
<add-to-wishlist
  [productData]="wishlistData"
  (dataEvent)="handleWishlistModalEvent($event)"
  *ngIf="showAddToWishListModal"
>
</add-to-wishlist>
<!-- ./Add To Wishlist Modal -->

<!-- User Plan list Modal start -->
<user-plan-modal
  [userSubscriptionPlans]="userSubscriptionPlans"
  (dataEvent)="handleUserPlanModalEvent($event)"
  [licenseId] = "AddToCartForm.value['choosenLicense']"
  [versionId] = ""
  *ngIf="showUserPlans"
>
</user-plan-modal>


<!--For Invali Data-->
<appv2-static-product-data [type] = "4" *ngIf="invalidData"></appv2-static-product-data>

<!--music player-->
<appv2-music-player [musicList]="musicPlayerData" [currentMusicIndex]="currentIndex" [isPlaying]= "isPlaying"*ngIf="musicPlayerData && displayMusicPlayer" (dataEvent)="handleClickPlay($event)"[userWishlistedData]="userWishlistedData"  [displayDownload]="displayDownload"></appv2-music-player>
