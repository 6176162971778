import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { footage, messages } from 'src/appv2/appv2.constants';
import {
  FeatureDecreaseWishlistCountAction,
  FeatureIncreaseCartCountAction,
  FeatureIncreaseWishlistCountAction
} from 'src/appv2/reducers/features/features.actions';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { CartService } from 'src/appv2/services/cart.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';

@Component({
  selector: 'appv2-footage-details',
  templateUrl: './footage-details.component.html',
  styleUrls: ['./footage-details.component.scss'],
})
export class FootageDetailsComponent implements OnInit {
  constructor(
    private userData: AccountService,
    private itemDetails: ItemDetailsService,
    private download: DownloadService,
    private cartActions: CartService,
    private store$: Store<any>,
    public router: Router,
    private wishlist: WishlistService,
    private toastService: ToastService,
    private activatedRouter: ActivatedRoute,
    private el:ElementRef
  ) {}

  tiltop: object = {
    tiltopClass: '',
    message: '',
  };

  itemData;
  requestData;
  prevRequestData;
  filterVersion: any = [];
  public wishlistData:any ={};
  user: string = '';
  keywords: string = '';
  relatedData: any = [];
  public relatedAuthorData:any = [];
  apiProductId: string;
  prevApiProductId: string;
  productWeb: string;
  productMainType: string;
  isRFLicence = false;
  isCommercial = false;
  isNonCommercial = false;
  isDigital = false;
  AddToCartForm: FormGroup;
  emptyUserPlansList = true;
  isShowCartMessage = false;
  userLogined: boolean = false;
  isShowPricingMessage = false;
  isWithoutRelatedData = false;
  plansChecked: boolean = false;
  userChoosedItem: boolean = false;

  itemsInRow = 4;
  addedToCartData: any;
  loadingStatus: boolean;
  creditedPackageId: any;
  showCartPopup: boolean = false;
  showUserPlans: boolean = false;

  showAddToWishListModal: boolean = false;
  ifUserHaveCreditedBalance: boolean = false;

  userSubscriptionPlans: Array<object> = [];
  getAlreadyDownloadedImageFlag: boolean = false;
  public apiProductSlug:string=''
  userWishlistedData: string[] = [];
  public displayDownload:Boolean =false;
  public selectedValue: string = '';
  public showDigital:Boolean = false;
  public showNonCommercial : Boolean =false;
  public showCommercial : Boolean = false;
  public showFullRF : Boolean = false;
  public displayCart : Boolean =true;
  public selectedPrice :any;
  public licenceData : any;
  public getAlreadyDownloadedImageData :any;
  public licenceArray = []
  public downloadPackageList = []
  public licenceCheck :number= 0
  public selectedLicense:any
  public priceCheck:number = 0
  public disabled:boolean = false
  public planId:number
  public userSamePlans : number = 0
  public selectedPlanLicence = []
  public selectedPlanSize = []
  public invalidData : Boolean = false
  isFullTitleDisplayed = false;
  truncatedTitle: string;


  // Link to item
  openItem(itemSlug) {
    this.router.navigate(['/footage',itemSlug]);
  }

  openSelectedPlans() {
    this.showUserPlans = false;
    let size = this.selectedPrice['version'] == '4K' ? 2 :1
    if(this.userSamePlans > 0 && this.itemData.is_premium == 0 && this.selectedPlanLicence.includes(this.AddToCartForm.value['choosenLicense'].toString()) && this.selectedPlanSize.includes(size.toString())){
        this.showUserPlans = true;
      }
      else if(this.itemData.is_premium == 1){
        this.toastService.warning(messages.footagePremiumProduct)
      } else{
        this.onSubmit(true,this.planId)
      }
  }

  // Get item info
  async getItemInfo() {
    this.licenceArray = [];
    this.getAlreadyDownloadedImageFlag = false
    this.plansChecked = false
    this.displayCart = true
    this.displayDownload = false
    this.invalidData =  false
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.footageDetailLoading  })
    );

    await this.itemDetails
      .getItemDetail(this.apiProductSlug,'Footage',3,true)
      .then((response) => {
        this.itemData = response;
        if(this.itemData && this.itemData.invalid == true){
          this.invalidData = true
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
        } else{
        if (response['video']) {
          this.itemDetails
            .getDownloadForTryFootageLink(response['mediaId'])
            .subscribe((data) => {
              response['downloadForTry']['link'] = data;
            });
        }
        let filterV = footage.full_rf
        this.filterData(filterV)
        this.licenceCheck = 0
         if (this.filterVersion.length > 0) {
          let getVersion = this.filterVersion[this.filterVersion.length-1];
          this.priceCheck = this.filterVersion.length-1

          this.selectedValue = getVersion[0]?.name;

          let vl = JSON.stringify(getVersion, null, 2).replace(
            /(\r\n|\n|\r)/gm,
            ''
          );
          this.AddToCartForm.controls['choosenItem'].patchValue(vl);
          this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])
        } else{
          this.disabled = true
        }

        if (this.user) {
          this.getAlreadyDownloadedImage(
            JSON.parse(this.user)['Utype'],
            this.itemData.api_product_id
          );
        }

        if (this.requestData == undefined) {
          this.getRelatedData(response['product_keywords'], true)
          this.getRealtedAuthor(this.itemData['author_username'])
        }
        this.truncateTitle()
        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
        }
      });
  }

  onClickKeyword(item) {
    const trimmedItem = item.trim();
    this.router.navigate(['/footages'], {
      queryParams: { search: trimmedItem }});
  }

  /**
   * Gets already downloaded image
   * @param userId
   * @param mediaId
   */
  getAlreadyDownloadedImage(userId, mediaId) {
    this.itemDetails
      .getAlreadyDownloadedImage(userId, mediaId)
      .subscribe((data) => {
        this.getAlreadyDownloadedImageData = data.data;
        if(this.getAlreadyDownloadedImageData.length > 0){

          this.licenceArray = this.getAlreadyDownloadedImageData.map(item => item.licence_type);
          if(this.licenceArray.includes(this.AddToCartForm.value['choosenLicense'].toString()) && this.displayDownload){
            this.getAlreadyDownloadedImageFlag = true
          }else{
            this.getAlreadyDownloadedImageFlag = false
          }
        }
        this.loadingStatus = false;
      });
  }

  // Get item info by link from wishlist
  getItemFromWishlist() {
    this.prevRequestData = this.requestData;
    this.productWeb = this.requestData['product_web'];
    this.apiProductId = this.requestData['api_product_id'];
  }

  copyId(element) {
    var textArea = document.createElement('textarea');
    textArea.value = element.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    document.querySelector('.number-copied')['style'].opacity = 1;
    setTimeout(() => {
      document.querySelector('.number-copied')['style'].opacity = 0;
    }, 1000);
  }
  // Add item to wishlist
  addToWishlist(itemId) {
    let temp = {
      product: itemId,
      tokenData: JSON.parse(localStorage.getItem('user_data')),
    };
    this.tiltop['tiltopClass'] = '';
    this.tiltop['message'] = '';
    this.wishlist.addToWishlist(temp).subscribe((data) => {
      this.toastService.warning(data.message);
      this.tiltop['tiltopClass'] = 'addToWishlist';
      switch (data.status) {
        case '1':
          this.store$.dispatch(new FeatureIncreaseWishlistCountAction());
          break;
        case '2':
          this.store$.dispatch(new FeatureDecreaseWishlistCountAction());
          break;
        default:
          break;
      }
    });
  }
  stringify(item) {
    return JSON.stringify(item);
  }

  // Add to cart and downloading via subscription
  onSubmit(download = false, planId = undefined) {

    let addToCartData = this.itemData.cartInfo;

    if (this.userLogined) {
      this.userChoosedItem = this.AddToCartForm.value['choosenItem']
      addToCartData['product']['token'] = localStorage.getItem('user_data');
      if (addToCartData['product'].type == 2) {
        addToCartData['product']['selected_product'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        );
        addToCartData['product']['total'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        )['price'];
        this.addedToCartData = addToCartData['product']['total'];
      } else if (addToCartData['product'].type == 3) {
        addToCartData['product']['product_info']['selected_product'] =
          this.AddToCartForm.value['choosenItem'];
        addToCartData['product']['extended'] = this.AddToCartForm.value['choosenLicense'];
        addToCartData['product']['selected_product'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        );
        addToCartData['product']['total'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        )['pr'];
        this.addedToCartData = addToCartData['product']['total'];
        addToCartData['product']['product_type'] = 'footage'
        if(this.itemData.versions.length > 0){
          addToCartData['product']['version_data']= this.itemData.versions/* .find(version => version.label ==JSON.parse(
            this.AddToCartForm.value['choosenItem']
          )['version']) */;
        }else{
          addToCartData['product']['version_data'] = []
        }


      }

      if (this.userChoosedItem) {
        if (download) {
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.downloadAddLoading })
          );
          addToCartData['product']['package'] = planId;

          this.download
          .downloadWithSubscriptionPlan(addToCartData)
          .subscribe((data) => {
          this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );

          this.showUserPlans = false;

          if (data.status == 'success') {
            this.toastService.success(data.message)
            this.getAlreadyDownloadedImageFlag = true;
            window.location.href = data.data['url'];
          } else {
            this.toastService.warning(data.message);
          }
          });
          } else {
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.cartAddLoading })
            );
            setTimeout(() => {
              this.tiltop['message'] = '';
            }, 100);
            this.tiltop['tiltopClass'] = '';
            this.cartActions.addToCart(addToCartData).subscribe((data) => {
              this.store$.dispatch(
                new loadingChangeAction(<LoadingState>{ loadingStatus: false })
              );

              if (data.status == '1') {
                this.store$.dispatch(new FeatureIncreaseCartCountAction());
                this.showCartPopup = true;
              }

              if (data.status == '0') {
                this.showCartPopup = false;
                this.toastService.success(data.message);
              }
              setTimeout(() => {
                this.showCartPopup = false;
              }, 10000);
            });
          }
        } else {
          setTimeout(() => {
            this.toastService.warning(messages.chooseSize);
            this.tiltop['tiltopClass'] = 'error';
          }, 100);
          this.tiltop['message'] = '';
        }
      } else {
        this.showCartMessage();
      }
    }



  // Message if user didn't choose size
  showCartMessage() {
    this.isShowCartMessage = true;
    setTimeout(() => {
      this.isShowCartMessage = false;
    }, 2000);
  }

  // Choose subscription for download
  downloadWithSubscriptionPlan(planId) {
    this.onSubmit(true, planId);
  }
  ngDoCheck() {
    if (this.apiProductSlug != this.prevApiProductId) {
      this.prevApiProductId = this.apiProductSlug;
      this.getItemInfo();
    } else if (
      this.prevRequestData != this.requestData &&
      this.requestData != undefined
    ) {
      this.getItemFromWishlist();
    }
    if (this.AddToCartForm != undefined) {
      this.userChoosedItem = this.AddToCartForm.value['choosenItem'];
      this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])

    }

    this.userLogined = localStorage.getItem('user_data') != null;
    this.emptyUserPlansList = this.userSubscriptionPlans.length == 0;
    if (this.userLogined && !this.plansChecked && this.itemData) {
      this.getUserInfo();
    }
  }

  // get user info
  getUserInfo() {
    this.userSubscriptionPlans = []
    this.userData
      .getUserInformation(
        JSON.parse(localStorage.getItem('user_data'))['Utype']
      )
      .subscribe((data) => {
        for (let i = 0; i < data['data']['plans'].length; i++) {
          if (
            data['data']['plans'][i]['package_type'].toLowerCase() == this.productMainType.toLowerCase()
          ) {
            if (
              data['data']['plans'][i]['downloaded_product'] <
                data['data']['plans'][i]['package_products_count']
            ) {

              this.planId = data['data']['plans'][i]['id']
              let licence_name = data['data']['plans'][i]['licence']['slug']
              let filterV = footage[licence_name]
              this.filterData(filterV)

              if (this.filterVersion.length > 0) {
                let getVersion:any

                if(data['data']['plans'][i]['pacage_size'] == 2){

                   getVersion = this.filterVersion.filter(item => item.version == '4K');
                }else{
                   getVersion = this.filterVersion.filter(item => item.version.includes('HD'));
                }
                this.AddToCartForm.controls['choosenLicense'].patchValue(data['data']['plans'][i]['licence']['id'])
                const indexOF = this.licenceData.findIndex(item => item.id == this.AddToCartForm.value['choosenLicense']);
                this.licenceCheck = indexOF
                if(getVersion.length == 0){
                  let getVersion = this.filterVersion[this.filterVersion.length-1];
                  this.selectedValue = getVersion.name;

                  let vl = JSON.stringify(getVersion, null, 2).replace(
                    /(\r\n|\n|\r)/gm,
                    ''
                  );
                  this.AddToCartForm.controls['choosenItem'].patchValue(vl);
                  this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])
                  this.displayCart = true;
                  this.displayDownload = false;
                } else {
                  this.planId = data['data']['plans'][i]['id']
                  this.selectedValue = getVersion[0]?.name;
                   let vl = JSON.stringify(getVersion[0], null, 2).replace(
                  /(\r\n|\n|\r)/gm,
                  ''
                  );
                  this.AddToCartForm.controls['choosenItem'].patchValue(vl);
                  this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])
                  this.displayDownload = true
                  if(this.itemData?.is_premium =='1'){
                    this.displayCart =true
                  } else{
                    this.displayCart = false
                  }
                  let indexOfObject =0
                  if(this.selectedPrice.version.includes('HD')){
                     indexOfObject = this.filterVersion.findIndex(item => item.version.includes('HD'));
                  } else{
                     indexOfObject = this.filterVersion.findIndex(data => data.price == this.selectedPrice.price);
                  }
                  this.priceCheck = indexOfObject
                  this.userSubscriptionPlans.push(data['data']['plans'][i]);

                }
              }else{
                this.disabled = true;
              }

            }

          }
        }
        const versionSet = new Set();
        if(this.userSubscriptionPlans.length > 0){
        this.userSubscriptionPlans.forEach((obj:any) => {
          let version = obj.footage_tier
          let size = obj.pacage_size
          const key = `${version}-${size}`;

          // If the key is already in the set, it's a duplicate
          if (versionSet.has(key)) {
            this.selectedPlanLicence.push(version.toString());
            this.selectedPlanSize.push(size.toString())
          } else {
            // If the key is not in the set, add it
            versionSet.add(key);
          }
        });
      }
        if(this.selectedPlanLicence.length >0){
          this.userSamePlans = 2;
        }
        this.emptyUserPlansList = this.userSubscriptionPlans.length == 0;
      });
    this.plansChecked = true;
  }
  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {
      const slug = params['slug'];
      if (slug) {
        this.apiProductSlug = slug
        // Do something with the slug when the route parameter changes
      }
    });
    this.productMainType='footage'
    this.displayDownload = false
    this.disabled = false
    this.AddToCartForm = new FormGroup({
      choosenItem: new FormControl(false, [Validators.required]),
      choosenLicense: new FormControl('1', [Validators.required]),
    });
    if (this.router.url != '/wishlist') {
      this.isWithoutRelatedData = false;
      this.apiProductSlug = this.activatedRouter.snapshot.paramMap.get('slug')
    } else {
      this.getItemFromWishlist();
      this.isWithoutRelatedData = true;
    }
    this.userLogined = localStorage.getItem('user_data') != null;

    this.user = localStorage.getItem('user_data');
    if (this.user) {
      this.userWishlistData();
    }
    this.getItemInfo();
    this.licenceDetails()

  }


  public handleInfoPopup(licence) {
    this.selectedLicense = licence;
    this.showFullRF = !this.showFullRF;

  }


  playVideo(videoElement) {
    videoElement.videoLoaded = true;

  }
  resetVideo(item){
    item.videoLoaded = false;
  }

  changeLicenceType(e) {
    if (e.target.value == 1) {
      this.filterVersion = this.itemData.versions;
      this.filterVersion = this.itemData.versions.filter(
        (book) => book.description === 'XXL'
      );
    } else {
      this.filterVersion = this.itemData.versions;
    }
  }

  /**
   * Handles events triggered from the Add To Wishlist Modal.
   * If the event indicates the need to close the modal, the flag to show the modal is set to false.
   * @param {object} event - The event object containing information about the modal action.
   */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
      if(this.user){
        this.userWishlistData()
      }
    }
  };

  handleUserPlanModalEvent = (event) => {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showUserPlans = false;
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
    } else {
      this.onSubmit(true, event);
    }
  };

  reDownload(mediaId) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.redownloadLoading })
    );
    const payload = {
      id_media: mediaId,
      product_id: this.itemData.productId,
      user_id: JSON.parse(this.user)['Utype'],
      type: this.itemData.cartInfo.product.type,
    };
    this.download.reDownloadImage(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data) {
        window.location.href = data['url'];
      } else {
        this.toastService.warning(data.message);
      }
    });
  }

  getRelatedData(keyword, isChecked = false) {


    this.loadingStatus = true

    if (isChecked) {
      keyword = keyword.slice(0,6)

      this.keywords += keyword + ','
    }
    this.itemDetails.getRelatedProducts('Footage', this.itemData.product_id, this.itemData.product_keywords).subscribe((data) => {
      this.relatedData = data.imgfootage
      this.relatedData.forEach((v:any) => {v.videoLoaded = false;})
      this.loadingStatus = false
    })
  }

  showModal(data) {
    // Set the flag to true, indicating that the modal should be displayed
    this.wishlistData['product_id'] = data.product_id
    this.wishlistData['itemPoster'] = data.product_thumbnail
    this.wishlistData['description'] = data.product_description
    this.wishlistData['author_username'] = data?.product_authorName
    this.wishlistData['title'] = data.product_title

    this.showAddToWishListModal = true;
  }

  showPricingLink() {
    this.router.navigate(['/pricing'])
  }

  getRealtedAuthor(authorName){
    this.loadingStatus = true
    this.itemDetails
      .getItemRelated('', this.productMainType, 'artist:'+authorName)
      .subscribe((data) => {
        this.relatedAuthorData = data.imgfootage;
        this.relatedAuthorData.forEach((v:any) => {v.videoLoaded = false;})

        this.loadingStatus = false;
      });

  }

  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        const productPathIds = res.data.map((item) => String(item.product_thumbnail));

        this.userWishlistedData = productPathIds;
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };

  changePrice(e, i) {

    if(this.userSubscriptionPlans){
      let licenceData:any
      if(e.target.attr == '4K'){
         licenceData =  this.userSubscriptionPlans.filter((item : any)=>item.pacage_size == 2)
      } else{
         licenceData =  this.userSubscriptionPlans.filter((item : any)=>item.pacage_size == 1)
      }

      if(licenceData.length ==0){
          this.displayCart = true
          this.displayDownload = false
      }else{

          if(this.itemData.is_premium == '1'){
            this.displayCart =true
          } else{
            this.displayCart = false
          }
          this.displayDownload = true
          this.planId = licenceData[0]['id']

      }
    }
    if (this.filterVersion.length > 1) {
      let getVersion = this.filterVersion[i];
      this.selectedValue = getVersion.name;
      let vl = JSON.stringify(getVersion, null, 2).replace(
        /(\r\n|\n|\r)/gm,
        ''
      );
      this.AddToCartForm.controls['choosenItem'].patchValue(vl);
      this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])
    }
  }

  public hideShowRF(name,id) {

    if (name == 'Full RF') {
      let filterV = footage.full_rf
      this.filterData(filterV)
    } else if(name == 'Commercial'){
      let filterV = footage.commercial
    this.filterData(filterV)
    }else if(name == 'Media Non-Commercial'){
      let filterV = footage.non_commercial
      this.filterData(filterV)
    }else{
      let filterV = footage.digital
      this.filterData(filterV)
    }
    this.AddToCartForm.controls['choosenLicense'].patchValue(id)
    if(this.filterVersion.length > 0){
      let getVersion = this.filterVersion[0];
      this.selectedValue = getVersion.name;
      let vl = JSON.stringify(getVersion, null, 2).replace(
        /(\r\n|\n|\r)/gm,
        ''
      );
      this.AddToCartForm.controls['choosenItem'].patchValue(vl);
      this.selectedPrice = JSON.parse(this.AddToCartForm.value['choosenItem'])
      this.priceCheck = 0
      if(this.userSubscriptionPlans){
        let licenceData =  this.userSubscriptionPlans.filter((item : any)=>item.footage_tier == id)

        if(licenceData.length ==0){
            this.displayCart = true
            this.displayDownload = false
        }else{
          let size = this.selectedPrice['version'] == '4K' ? 2 :1
          let priceData = licenceData.filter((item:any) => item.pacage_size == size);
          if(priceData.length == 0){
            this.displayCart = true
            this.displayDownload = false
          }else{
            if(this.itemData.is_premium == '1'){
              this.displayCart =true
            } else{
              this.displayCart = false
            }
            this.displayDownload = true
            this.planId = licenceData[0]['id']
          }
        }
      }
    }else{
      this.disabled = true
    }


    if(this.licenceArray.includes(id.toString()) && this.displayDownload){
      this.getAlreadyDownloadedImageFlag = true
    }else{
      this.getAlreadyDownloadedImageFlag = false
    }


  }

  public hideShowCommercial() {
    this.isRFLicence = false;
    this.isCommercial = !this.isCommercial;
    this.isNonCommercial = false;
    this.isDigital = false;
    let filterV = footage.commercial
    this.filterData(filterV)

  }

  public hideShowNonCommercial() {
    this.isRFLicence = false;
    this.isCommercial = false;
    this.isNonCommercial = !this.isNonCommercial;
    this.isDigital = false;
    let filterV = footage.non_commercial
    this.filterData(filterV)
  }

  public hideShowDigital() {
    this.isRFLicence = false;
    this.isCommercial = false;
    this.isNonCommercial = false;
    this.isDigital = !this.isDigital;
    let filterV = footage.digital
    this.filterData(filterV)

  }

  public redownloadProduct(){
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );

     let payload = {
        id_media: this.itemData['mediaId'],
        product_id: this.itemData.product_id,
        user_id: JSON.parse(this.user)['Utype'],
        type: 3,
      };

    this.download.reDownloadImage(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == 'success') {
        this.toastService.success(data.message);
        window.location.href = data.data['download_url'];
      } else {
        this.toastService.warning(data.message);
      }
    });
  }

  @HostListener('document:click',['$event','$event.target'])

  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.el.nativeElement.contains(targetElement);
    if((this.showFullRF && targetElement.className !== 'rf-img') || !this.showFullRF ){
      this.showFullRF = false
    }
    if((this.showCommercial && targetElement.className !== 'commercial-img') || !this.showCommercial){
      this.showCommercial = false
    }
    if((this.showNonCommercial && targetElement.className !== 'noncommercial-img') || !this.showNonCommercial){
      this.showNonCommercial = false
    }
    if((this.showDigital && targetElement.className !== 'digital-img') || !this.showDigital){
      this.showDigital = false
    }
  }

  public filterData(filterV){
    let filterPremium
    if(this.itemData){
        if(this.itemData?.is_premium == 0){
           filterPremium = filterV.filter(item=>item.is_premium == 0)
        }else{
          filterPremium = filterV.filter(item=>item.is_premium == 1)
        }
        this.filterVersion = []
        this.filterVersion = this.itemData.versions;
      }

  }

  public licenceDetails(){
    const payload = '2'
    this.itemDetails.getLicenceDetails(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if(data.status == 'success'){
        this.licenceData = data.data

        if(this.userSubscriptionPlans.length ==0){

           this.AddToCartForm.controls['choosenLicense'].patchValue(this.licenceData[0].id);
        }
        const indexOfObject = this.licenceData.findIndex(item => item.id == this.AddToCartForm.value['choosenLicense']);
        this.licenceCheck = indexOfObject

        if(this.licenceArray.includes(this.AddToCartForm.value['choosenLicense'].toString()) && this.displayDownload){
          this.getAlreadyDownloadedImageFlag = true
        }else{
          this.getAlreadyDownloadedImageFlag = false
        }
      }else{
        this.licenceData = []
      }


    });
  }

  toggleTitle(): void {
    this.isFullTitleDisplayed = !this.isFullTitleDisplayed;
  }

   truncateTitle(): void {
    if (this.itemData?.product_title.length > 60) {
      this.truncatedTitle = this.itemData?.product_title.substring(0, 60);
    } else {
      this.truncatedTitle = this.itemData?.product_title;
    }
  }


}
