import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { image, messages } from 'src/appv2/appv2.constants';
import {
  FeatureDecreaseCartCountAction,
  FeatureDecreaseWishlistCountAction,
  FeatureIncreaseCartCountAction,
  FeatureIncreaseWishlistCountAction,
} from 'src/appv2/reducers/features/features.actions';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { CartService } from 'src/appv2/services/cart.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { Config, LayoutStyle, Media } from 'ng-opengallery';

@Component({
  selector: 'appv2-image-description',
  templateUrl: './image-description.component.html',
  styleUrls: ['./image-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageDescriptionComponent implements OnInit {
  constructor(
    private userData: AccountService,
    private itemDetails: ItemDetailsService,
    private download: DownloadService,
    private cartActions: CartService,
    private store$: Store<any>,
    public router: Router,
    private wishlist: WishlistService,
    private toastService: ToastService,
    private activatedRouter: ActivatedRoute,
    private el: ElementRef
  ) {}
  requestData;
  prevRequestData;

  getAlreadyDownloadedImageFlag: boolean = false;

  user: string = '';
  apiProductId: string;
  prevApiProductId: string;
  productWeb: string;
  productMainType: string;
  itemData;
  relatedData: any = [];
  isWithoutRelatedData = false;

  userSubscriptionPlans: Array<object> = [];
  userWishlistedData: string[] = [];

  emptyUserPlansList = true;
  isShowPricingMessage = false;

  isShowCartMessage = false;
  isExtended = false;
  isStandard = false;
  keywords: string = '';

  AddToCartForm: FormGroup;
  userLogined: boolean = false;
  plansChecked: boolean = false;
  userChoosedItem: boolean = false;
  extendedInfo: boolean = false;
  standardInfo: boolean = false;
  filterVersion: any = [];
  // show: boolean = false;

  tiltop: object = {
    tiltopClass: '',
    message: '',
  };

  /**
   * If user have credited balance of image description component.
   */
  ifUserHaveCreditedBalance: boolean = false;
  creditedPackageDetails: any;
  loadingStatus: boolean;
  itemsInRow = 4;
  addedToCartData: any;
  showCartPopup: boolean = false;
  showUserPlans: boolean = false;

  showAddToWishListModal: boolean = false;
  selectedValue: string = '';
  isSingle: boolean = false;
  isRelatedAuthorImages: any = [];
  public apiProductSlug: string = '';
  public displayDownload: Boolean = false;

  public showStandardPopup: Boolean = false;
  public hideExtendedLic: Boolean = false;
  public hideDownloadPanther: Boolean = false;
  public displayCart: Boolean = true;

  public licenceData: any;
  public getAlreadyDownloadedImageData: any;
  public licenceArray = [];
  public downloadPackageList = [];
  public licenceCheck: number = 0;
  public selectedLicense: any;
  public userProfileData: any;
  public userSamePlans: number = 0;
  public planId: number;
  public selectedPlanLicence = [];
  public isDownloadPack: Boolean = false;
  public invalidData: Boolean = false;
  isFullTitleDisplayed = false;
  truncatedTitle: string;

  config: Config = {
    diaporamaDuration: 3,
    layout: LayoutStyle.MASONRY,
    prefMediaHeight: 400,
    spacing: 0.2,
    viewerEnabled: false,
    enableAutoPlay: false,
  };
  public relatedDataAuthor: any = [];
  public similarImagesData: any = [];
  // Link to item
  openItem(slug) {
    this.router.navigate(['/image', slug]);
  }

  openSelectedPlans() {
    this.showUserPlans = false;

    if (
      this.userSamePlans > 1 &&
      this.itemData.is_premium == 0 &&
      this.selectedPlanLicence.includes(
        this.AddToCartForm.value['choosenLicense'].toString()
      )
    ) {
      this.showUserPlans = true;
    } else if (this.itemData.is_premium == 1) {
      this.toastService.warning(messages.footagePremiumProduct);
    } else {
      this.onSubmit(true, this.planId);
    }
  }

  // Get item info
  async getItemInfo() {
    this.licenceArray = [];
    this.getAlreadyDownloadedImageFlag = false;
    this.plansChecked = false;
    this.displayDownload = false;
    this.hideDownloadPanther = false;
    this.invalidData = false;
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{
        loadingStatus: true,
        loadingMessage: messages.imageDetailLoading,
      })
    );

    await this.itemDetails
      .getItemDetail(this.apiProductSlug, 'Image', 2)
      .then((response) => {
        this.itemData = response;
        if (this.itemData && this.itemData.invalid == true) {
          this.invalidData = true;
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
        } else {
          let filterV = image.standard;
          this.filterData(filterV);

          if (this.filterVersion.length > 0) {
            let getVersion = this.filterVersion[this.filterVersion.length - 1];
            this.selectedValue = getVersion.name;

            let vl = JSON.stringify(getVersion, null, 2).replace(
              /(\r\n|\n|\r)/gm,
              ''
            );
            this.AddToCartForm.controls['choosenItem'].patchValue(vl);
          }
          if (this.user) {
            this.getAlreadyDownloadedImage(
              JSON.parse(this.user)['Utype'],
              this.itemData.api_product_id
            );
          }

          if (this.requestData == undefined) {
            this.getRelatedData(response['product_keywords'], true);
            this.relatedauthorData();
          }
          this.truncateTitle();

          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
        }
      });
  }

  /**
   * Gets already downloaded image
   * @param userId
   * @param mediaId
   */
  getAlreadyDownloadedImage(userId, mediaId) {
    // TODO : need to update the code (need to show re-download button on specific size selection (onchange))
    this.itemDetails
      .getAlreadyDownloadedImage(userId, mediaId)
      .subscribe((data) => {
        this.getAlreadyDownloadedImageData = data.data;
        if (this.getAlreadyDownloadedImageData.length > 0) {
          this.licenceArray = this.getAlreadyDownloadedImageData.map(
            (item) => item.licence_type
          );
          if (
            this.licenceArray.includes(
              this.AddToCartForm.value['choosenLicense'].toString()
            ) &&
            this.displayDownload
          ) {
            this.getAlreadyDownloadedImageFlag = true;
          } else {
            this.getAlreadyDownloadedImageFlag = false;
          }
        }
        this.loadingStatus = false;
      });
  }

  // Get related to item data
  getRelatedData(keyword, isChecked = false) {
    this.loadingStatus = true;
    this.itemDetails
      .getRelatedProducts('Image', this.itemData.product_id, this.itemData.product_keywords)
      .subscribe((data) => {
        this.relatedData = data.imgfootage;
        this.relatedDataAuthor = [];
        for (let i = 0; i < this.relatedData.length; i++) {
          this.relatedDataAuthor.push(
            new Media(
              this.relatedData[i]['product_thumbnail'],
              this.relatedData[i]['product_title']
            )
          );
        }
        this.loadingStatus = false;
      });
  }

  onClickKeyword(item) {
    const trimmedItem = item.trim();
    this.router.navigate(['/images'], {
      queryParams: { search: trimmedItem },
    });
  }

  // Get item info by link from wishlist
  getItemFromWishlist() {
    this.prevRequestData = this.requestData;
    this.productWeb = this.requestData['product_web'];
    this.apiProductId = this.requestData['api_product_id'];
  }

  copyId(element) {
    var textArea = document.createElement('textarea');
    textArea.value = element.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    document.querySelector('.number-copied')['style'].opacity = 1;
    setTimeout(() => {
      document.querySelector('.number-copied')['style'].opacity = 0;
    }, 1000);
  }
  // Add item to wishlist
  addToWishlist(itemId) {
    let temp = {
      product: itemId,
      tokenData: JSON.parse(localStorage.getItem('user_data')),
    };
    this.tiltop['tiltopClass'] = '';
    this.tiltop['message'] = '';
    this.wishlist.addToWishlist(temp).subscribe((data) => {
      //this.tiltop['message'] = data.message
      this.toastService.warning(data.message);
      this.tiltop['tiltopClass'] = 'addToWishlist';
      switch (data.status) {
        case '1':
          this.store$.dispatch(new FeatureIncreaseWishlistCountAction());
          break;
        case '2':
          this.store$.dispatch(new FeatureDecreaseWishlistCountAction());
          break;
        default:
          break;
      }
    });
  }
  stringify(item) {
    return JSON.stringify(item);
  }

  // Add to cart and downloading via subscription
  onSubmit(download = false, planId = undefined) {
    let addToCartData = this.itemData.cartInfo;
    console.log("addToCartData", addToCartData);
    
    if (this.userLogined) {
      addToCartData['product']['token'] = localStorage.getItem('user_data');
      if (addToCartData['product'].type == 2) {
        addToCartData['product']['selected_product'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        );
        addToCartData['product']['total'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        )['price'];
        addToCartData['product']['extended'] =
          this.AddToCartForm.value['choosenLicense'];
        this.addedToCartData = addToCartData['product']['total'];
        if (this.itemData.versions.length > 0) {
          addToCartData['product']['version_data'] =
            this.itemData.versions/* .find((version) => version.label === 'XL') */;
        } else {
          addToCartData['product']['version_data'] = [];
        }
      } else if (addToCartData['product'].type == 3) {
        addToCartData['product']['product_info']['selected_product'] =
          this.AddToCartForm.value['choosenItem'];
        addToCartData['product']['selected_product'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        );
        addToCartData['product']['total'] = JSON.parse(
          this.AddToCartForm.value['choosenItem']
        )['pr'];
        this.addedToCartData = addToCartData['product']['total'];
        if (this.itemData.versions.length > 0) {
          addToCartData['product']['version_data'] =
            this.itemData.versions/* .find((version) => version.label === 'XL') */;
        } else {
          addToCartData['product']['version_data'] = [];
        }
      }

      if (this.userChoosedItem) {
        if (download) {
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{
              loadingStatus: true,
              loadingMessage: messages.downloadAddLoading,
            })
          );
          let foundObject: any = {};
          if (this.itemData.product_web == 3) {
            foundObject = this.userSubscriptionPlans.find(function (obj: any) {
              return obj.id == planId;
            });

            if (foundObject && foundObject?.package_plan == 2) {
              this.toastService.warning(messages.chooseDownloaPack);
              this.store$.dispatch(
                new loadingChangeAction(<LoadingState>{ loadingStatus: false })
              );
              return;
            }
          }
          addToCartData['product']['package'] = planId;
          this.download
            .downloadWithSubscriptionPlan(addToCartData)
            .subscribe((data) => {
              this.store$.dispatch(
                new loadingChangeAction(<LoadingState>{ loadingStatus: false })
              );
              this.showUserPlans = false;
              if (
                data.download_status &&
                data.download_status.status == 'pending'
              ) {
                this.getAlreadyDownloadedImageFlag = true;
                this.toastService.warning(messages.downloadSoon);
              } else if (data.status == 'success') {
                this.getAlreadyDownloadedImageFlag = true;
                window.location.href = data.data['url'];
              } else if (data.stat == 'fail') {
                this.toastService.warning(data.err.msg);
              } else {
                this.toastService.warning(data.message);
              }
            });
        } else {
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{
              loadingStatus: true,
              loadingMessage: messages.cartAddLoading,
            })
          );
          setTimeout(() => {
            this.tiltop['message'] = '';
          }, 100);
          this.tiltop['tiltopClass'] = '';
          this.cartActions.addToCart(addToCartData).subscribe((data) => {
            this.store$.dispatch(
              new loadingChangeAction(<LoadingState>{ loadingStatus: false })
            );

            if (data.status == '1') {
              this.store$.dispatch(new FeatureIncreaseCartCountAction());
              this.showCartPopup = true;
            }

            if (data.status == '0') {
              this.showCartPopup = false;
              this.toastService.success(data.message);
            }
            setTimeout(() => {
              this.showCartPopup = false;
            }, 10000);
          });
        }
      } else {
        setTimeout(() => {
          this.toastService.warning(messages.chooseSize);
          this.tiltop['tiltopClass'] = 'error';
        }, 100);
        this.tiltop['message'] = '';
      }
    } else {
      this.showCartMessage();
    }
  }
  // Message if user doesn't have subscription
  showPricingLink() {
    this.router.navigate(['/pricing']);
  }

  // Message if user didn't choose size
  showCartMessage() {
    this.isShowCartMessage = true;
    setTimeout(() => {
      this.isShowCartMessage = false;
    }, 2000);
  }

  // Choose subscription for download
  downloadWithSubscriptionPlan(planId) {
    this.onSubmit(true, planId);
  }
  ngDoCheck() {
    if (this.apiProductSlug != this.prevApiProductId) {
      this.prevApiProductId = this.apiProductSlug;
      this.getItemInfo();
    } else if (
      this.prevRequestData != this.requestData &&
      this.requestData != undefined
    ) {
      this.getItemFromWishlist();
    }
    if (this.AddToCartForm != undefined) {
      this.userChoosedItem = this.AddToCartForm.value['choosenItem'];
    }

    this.userLogined = localStorage.getItem('user_data') != null;
    this.emptyUserPlansList = this.userSubscriptionPlans.length == 0;
    if (this.userLogined && !this.plansChecked && this.itemData) {
      this.getUserInfo();
    }
  }

  // get user info
  getUserInfo() {
    this.userSubscriptionPlans = [];
    this.userData
      .getUserInformation(
        JSON.parse(localStorage.getItem('user_data'))['Utype']
      )
      .subscribe((data) => {
        this.userProfileData = data.data;
        for (let i = 0; i < data['data']['plans'].length; i++) {
          if (
            data['data']['plans'][i]['package_type'].toLowerCase() ==
            this.productMainType.toLowerCase()
          ) {
            if (
              data['data']['plans'][i]['downloaded_product'] <
              data['data']['plans'][i]['package_products_count']
            ) {
              this.planId = data['data']['plans'][i]['id'];
              if (this.itemData?.product_web == 2) {
                if (this.licenceData) {
                  this.licenceData = this.licenceData.filter(
                    (item) => item.slug !== 'extended'
                  );
                }
              }

              if (
                data['data']['plans'][i]['package_plan'] == '2' &&
                this.itemData?.product_web == 3 &&
                !this.isDownloadPack
              ) {
                this.hideDownloadPanther = true;
              }
              if (
                data['data']['plans'][i]['package_plan'] == '1' &&
                this.itemData?.product_web == 3
              ) {
                this.hideDownloadPanther = false;
                this.isDownloadPack = true;
              }
              let licence_name = data['data']['plans'][i]['licence']['slug'];
              let filterV = image[licence_name];
              let filterVer = this.filterData(filterV);

              if (filterVer) {
                let getVersion = filterVer[filterVer.length - 1];
                this.selectedValue = getVersion.name;

                let vl = JSON.stringify(getVersion, null, 2).replace(
                  /(\r\n|\n|\r)/gm,
                  ''
                );
                this.AddToCartForm.controls['choosenItem'].patchValue(vl);
                this.AddToCartForm.controls['choosenLicense'].patchValue(
                  data['data']['plans'][i]['licence']['id']
                );
                if (this.licenceData) {
                  const indexOfObject = this.licenceData.findIndex(
                    (item) =>
                      item.id == this.AddToCartForm.value['choosenLicense']
                  );
                  this.licenceCheck = indexOfObject;
                }

                this.displayDownload = true;

                if (this.itemData.is_premium == '1') {
                  this.displayCart = true;
                } else {
                  this.displayCart = false;
                }
                this.userSubscriptionPlans.push(data['data']['plans'][i]);
              }
            }
          }
        }
        const versionSet = new Set();
        if (this.userSubscriptionPlans.length > 0) {
          this.userSubscriptionPlans.forEach((obj: any) => {
            let version = obj.footage_tier;
            // If the version is already in the set, it's a duplicate
            if (versionSet.has(version)) {
              this.selectedPlanLicence.push(version.toString());
            } else {
              // If version is not in the set, add it
              versionSet.add(version);
            }
          });
        }
        if (this.selectedPlanLicence.length > 0) {
          this.userSamePlans = 2;
        }

        this.emptyUserPlansList = this.userSubscriptionPlans.length == 0;
      });
    this.plansChecked = true;
  }
  ngOnInit(): void {
    this.activatedRouter.params.subscribe((params) => {
      const slug = params['slug'];
      if (slug) {
        this.apiProductSlug = slug;
        // Do something with the slug when the route parameter changes
      }
    });
    this.productMainType = 'image';
    this.displayDownload = false;
    this.AddToCartForm = new FormGroup({
      choosenItem: new FormControl(false, [Validators.required]),
      choosenLicense: new FormControl('1', [Validators.required]),
    });
    //this.displayCart = false
    if (this.router.url != '/wishlist') {
      this.isWithoutRelatedData = false;
      this.apiProductSlug = this.activatedRouter.snapshot.paramMap.get('slug');
    } else {
      this.getItemFromWishlist();
      this.isWithoutRelatedData = true;
    }
    this.getItemInfo();

    this.userLogined = localStorage.getItem('user_data') != null;
    this.user = localStorage.getItem('user_data');

    if (this.user) {
      this.userWishlistData();
    }
    this.licenceDetails();
  }

  public hideShowExtendedInfo(licence) {
    this.selectedLicense = licence;
    this.extendedInfo = !this.extendedInfo;
  }

  public hideShowStandardInfo() {
    this.extendedInfo = false;
    this.showStandardPopup = !this.showStandardPopup;
  }

  changeLicenceType(e) {
    if (e.target.value == 1) {
      this.filterVersion = this.itemData.versions;
      this.filterVersion = this.itemData.versions.filter(
        (book) => book.description === 'XXL'
      );
      if (this.filterVersion.length > 0) {
        let getVersion = this.filterVersion[0];
        this.selectedValue = getVersion.name;
        let vl = JSON.stringify(getVersion, null, 2).replace(
          /(\r\n|\n|\r)/gm,
          ''
        );
        this.AddToCartForm.controls['choosenItem'].patchValue(vl);
      }
    } else {
      this.filterVersion = this.itemData.versions;
      if (this.filterVersion.length > 1) {
        let getVersion = this.filterVersion[0];
        this.selectedValue = getVersion.name;
        let vl = JSON.stringify(getVersion, null, 2).replace(
          /(\r\n|\n|\r)/gm,
          ''
        );
        this.AddToCartForm.controls['choosenItem'].patchValue(vl);
      }
    }
  }

  changePrice(e, i) {
    if (this.filterVersion.length > 1) {
      let getVersion = this.filterVersion[i];
      this.selectedValue = getVersion.name;
      let vl = JSON.stringify(getVersion, null, 2).replace(
        /(\r\n|\n|\r)/gm,
        ''
      );
      this.AddToCartForm.controls['choosenItem'].patchValue(vl);
    }
  }

  /**
   * Display the "Add to Wishlist" modal.
   */
  showModal() {
    // Set the flag to true, indicating that the modal should be displayed
    this.showAddToWishListModal = true;
  }

  removeFromCart(itemId) {
    let itemIdObj = {
      product: {
        cart_id: itemId,
      },
    };
    this.cartActions.removeFromCart(itemIdObj).subscribe((data) => {
      if (data['status'] == '1') {
        this.store$.dispatch(new FeatureDecreaseCartCountAction());
        setTimeout(() => {
          this.tiltop['message'] = data['message'];
        }, 100);
        this.tiltop['message'] = '';
      } else {
        setTimeout(() => {
          this.tiltop['message'] = data['message'];
        }, 100);
        this.tiltop['message'] = '';
      }
    });
  }

  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        const productPathIds = res.data.map((item) =>
          String(item.product_thumbnail)
        );

        this.userWishlistedData = productPathIds;
      },
      (error) => {
        console.log('error : ', error);
      }
    );
  };

  /**
   * Handles events triggered from the Add To Wishlist Modal.
   * If the event indicates the need to close the modal, the flag to show the modal is set to false.
   * @param {object} event - The event object containing information about the modal action.
   */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      this.showAddToWishListModal = false;
      if (this.user) {
        this.userWishlistData();
      }

      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
    }
  };

  handleUserPlanModalEvent = (event) => {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true })
    );
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showUserPlans = false;
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
    } else {
      this.onSubmit(true, event);
    }
  };

  reDownload(mediaId) {
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{
        loadingStatus: true,
        loadingMessage: messages.redownloadLoading,
      })
    );
    const payload = {
      id_media: this.itemData.mediaId,
      product_id: this.itemData.product_id,
      user_id: JSON.parse(this.user)['Utype'],
      type: this.itemData.cartInfo.product.type,
    };
    this.download.reDownloadImage(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == 'success') {
        this.toastService.success(data.message);
        window.location.href = data.data.download_url;
      } else if (data.status == 'pending') {
        this.toastService.warning(data.message);
      } else {
        this.toastService.warning('Something went wrong.');
      }
    });
  }
  private relatedauthorData() {
    this.itemDetails
      .getItemRelatedSearchImage(
        '',
        this.productMainType,
        'artist:'+this.itemData.author_username
      )
      .subscribe((data) => {
        this.isRelatedAuthorImages = data.imgfootage;
        this.similarImagesData = [];
        for (let i = 0; i < this.isRelatedAuthorImages.length; i++) {
          this.similarImagesData.push(
            new Media(
              this.isRelatedAuthorImages[i]['product_thumbnail'],
              this.isRelatedAuthorImages[i]['product_title']
            )
          );
        }
        this.loadingStatus = false;
      });
  }

  public hideShowExtended(name, id) {
    if (name == 'Standard') {
      let filterV = image.standard;
      this.filterData(filterV);
    } else {
      let filterV = image.extended;
      this.filterData(filterV);
    }
    this.AddToCartForm.controls['choosenLicense'].patchValue(id);
    if (this.userSubscriptionPlans) {
      let licenceData = this.userSubscriptionPlans.filter(
        (item: any) => item.footage_tier == id
      );
      if (licenceData.length == 0) {
        this.displayCart = true;
        this.displayDownload = false;
      } else {
        if (this.itemData.is_premium == '1') {
          this.displayCart = true;
        } else {
          this.displayCart = false;
        }
        this.displayDownload = true;
        this.planId = licenceData[0]['id'];
      }
    }

    if (this.licenceArray.includes(id.toString()) && this.displayDownload) {
      this.getAlreadyDownloadedImageFlag = true;
    } else {
      this.getAlreadyDownloadedImageFlag = false;
    }
  }

  public hideShowStandard() {
    this.isExtended = false;
    this.isStandard = !this.isStandard;
    let filterV = image.standard;
    this.filterData(filterV);
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.el.nativeElement.contains(targetElement);
    if (
      (this.extendedInfo && targetElement.className !== 'extended-img') ||
      !this.extendedInfo
    ) {
      this.extendedInfo = false;
    }
    if (
      (this.showStandardPopup && targetElement.className !== 'standard-img') ||
      !this.showStandardPopup
    ) {
      this.showStandardPopup = false;
    }
  }

  public openToastr(msg) {
    this.toastService.warning(msg);
  }

  public filterData(filterV) {
    let filterPremium;

    if (this.itemData) {
      if (this.itemData && this.itemData?.is_premium == '0') {
        filterPremium = filterV.filter((item) => item.is_premium == 0);
      } else {
        filterPremium = filterV.filter((item) => item.is_premium == 1);
      }
      this.filterVersion = [];
      // this.filterVersion = filterPremium;
      this.filterVersion = this.itemData.versions;
      if (filterPremium && filterPremium[0]) {
        this.AddToCartForm.controls['choosenItem'].patchValue(
          JSON.stringify(filterPremium[0])
        );
      }
    } else {
      //this.filterData(filterV)
    }
    return filterPremium;
  }

  public licenceDetails() {
    const payload = '1';
    this.itemDetails.getLicenceDetails(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == 'success') {
        this.licenceData = data.data;
        if (this.userSubscriptionPlans.length == 0) {
          this.AddToCartForm.controls['choosenLicense'].patchValue(
            this.licenceData[0].id
          );
        }
        if (this.userSubscriptionPlans.length > 0) {
          let planDataSub = this.userSubscriptionPlans.filter(
            (item: any) => item.package_plan == '2'
          );
          if (
            planDataSub &&
            this.itemData?.product_web == 3 &&
            !this.isDownloadPack
          ) {
            this.hideDownloadPanther = true;
          }
          let planDataPack = this.userSubscriptionPlans.filter(
            (item: any) => item.package_plan == '1'
          );
          if (planDataPack && this.itemData?.product_web == 3) {
            this.hideDownloadPanther = true;
          }
        }
        if (this.itemData?.product_web == 2) {
          if (this.licenceData) {
            this.licenceData = this.licenceData.filter(
              (item) => item.slug !== 'extended'
            );
          }
        }
        const indexOfObject = this.licenceData.findIndex(
          (item) => item.id == this.AddToCartForm.value['choosenLicense']
        );
        this.licenceCheck = indexOfObject;

        if (
          this.licenceArray.includes(
            this.AddToCartForm.value['choosenLicense'].toString()
          ) &&
          this.displayDownload
        ) {
          this.getAlreadyDownloadedImageFlag = true;
        } else {
          this.getAlreadyDownloadedImageFlag = false;
        }
      } else {
        this.licenceData = [];
      }
    });
  }

  toggleTitle(): void {
    this.isFullTitleDisplayed = !this.isFullTitleDisplayed;
  }

  truncateTitle(): void {
    if (this.itemData?.product_title.length > 60) {
      this.truncatedTitle = this.itemData?.product_title.substring(0, 60);
    } else {
      this.truncatedTitle = this.itemData?.product_title;
    }
  }

  openItemGallerySimilar(item) {
    const objectValues = Object.values(this.relatedData);
    const foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item.previewUrl
    );
    if (foundObject != undefined) {
      this.router.navigate(['/image/' + foundObject['slug']]);
    }
  }

  openItemGalleryauthor(item) {
    const objectValues = Object.values(this.isRelatedAuthorImages);
    const foundObject = objectValues.find(
      (value) => (value as any).product_thumbnail === item.previewUrl
    );
    if (foundObject != undefined) {
      this.router.navigate(['/image/' + foundObject['slug']]);
    }
  }
}
