import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SafeRequestService } from './safe-request.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ItemDetailsService {
  private baseUrl = environment.url;
  constructor(private safeRequest: SafeRequestService,private http: HttpClient) {
  }

  // Parse item object to necessary form (save only necessary data)
  async getItemDetail(apiProductSlug, type='', productWeb = 1,singleFootageData=false): Promise<Observable<any>> {
    let itemDetails
    let itemDetailsValidated
    //const url = 'http://18.119.119.199/api/details/' + apiProductId + '/' + productWeb + '/' + productMainType
    const url = this.baseUrl + 'details'
    const payload ={
      slug:  apiProductSlug,
      type:type
    }
    // const url = 'https://imagefootage.com/backend/api/details/' + apiProductId + '/' + productWeb + '/' + productMainType
    let response = await fetch(url,{
      method: "POST",
      body: JSON.stringify(payload),
    })
    itemDetails = await response.json()
    itemDetails = itemDetails.data
    if(itemDetails == null){
      itemDetailsValidated = {
        invalid: true
      }
    }


    if (productWeb == 2 && itemDetails) {
      itemDetailsValidated = {
        video: false,
        product_id: itemDetails.product_id,
        mediaId: itemDetails.api_product_id,
        date: itemDetails.created_at,
        product_title: itemDetails.product_title,
        product_keywords: itemDetails.product_keywords,
        product_main_image: itemDetails.product_main_image,
        product_description: itemDetails.product_description,
        author_username: itemDetails.auther_name,
        versions: itemDetails.options|| [],
        api_product_id:itemDetails.api_product_id,
        slug:itemDetails.slug,
        licence_type:itemDetails.licence_type,
        product_thumbnail:itemDetails.product_thumbnail,
        product_main_type:itemDetails?.product_main_type,
        is_premium:itemDetails?.is_premium && itemDetails?.is_premium != "" ? itemDetails?.is_premium : 0,
        product_web: itemDetails?.product_web,
        editorial:itemDetails?.attributes?.editorial || 0,
        invalid:false,
        downloadForTry: {
          link: itemDetails.product_main_image,
          name: itemDetails.product_main_image
        },
        cartInfo: {
          product: {
            product_info: {
              media: {
                id: itemDetails.api_product_id,
                thumb_170_url: itemDetails.product_thumbnail
              },
              metadata: {
                title: itemDetails.product_title,
                description: itemDetails.product_description
              },
              productWeb: productWeb
            },
            extended: false,
            type: 2
          }
        }
      }
    } else if (productWeb == 3 && itemDetails) {
      if(singleFootageData){
        itemDetailsValidated = {
          video: true,
          product_id: itemDetails.product_id,
          mediaId: itemDetails.api_product_id,
          date: itemDetails.created_at,
          product_title: itemDetails.product_title,
          product_keywords: itemDetails.product_keywords,
          product_main_image: itemDetails.product_main_image,
          product_description: itemDetails.product_description,
          author_username: itemDetails.auther_name,
          versions: itemDetails?.options,
          api_product_id:itemDetails.api_product_id,
          editorial:itemDetails?.attributes?.editorial || 0,
          slug:itemDetails.slug,
          licence_type:itemDetails.licence_type,
          product_thumbnail:itemDetails.product_thumbnail,
          product_main_type:itemDetails?.product_main_type,
          is_premium:itemDetails?.is_premium && itemDetails?.is_premium != "" ? itemDetails?.is_premium : 0,
          product_web: itemDetails?.product_web,
          invalid:false,
          downloadForTry: {
            link: '',
            name: itemDetails.product_main_image
          },
          cartInfo: {
            product: {
              product_info: {
                0: {
                  flv_base: itemDetails.product_thumbnail,
                  clip_data: {
                    pic_objectid: itemDetails.product_id,
                    pic_description: itemDetails.product_description,
                    n: itemDetails.product_title
                  }
                },
                1: itemDetails.product_thumbnail,
                2: itemDetails.product_thumbnail,
                selected_product: {

                },
                media: {
                  id: itemDetails.api_product_id,
                },
                productWeb: productWeb,
              },
              type: 3
            }
          }
        }
      } else {
        itemDetailsValidated = {
          video: true,
          product_id: itemDetails.product_id,
          mediaId: itemDetails.api_product_id,
          date: itemDetails.created_at,
          product_title: itemDetails.product_title,
          product_keywords: itemDetails.product_keywords,
          product_main_image: itemDetails.product_main_image,
          product_description: itemDetails.product_description,
          author_username: itemDetails.auther_name,
          versions: itemDetails?.options,
          api_product_id:itemDetails.api_product_id,
          editorial:itemDetails?.attributes?.editorial || 0,
          slug:itemDetails.slug,
          music_price:itemDetails.music_price,
          music_duration:itemDetails.music_duration,
          music_sound_bpm:itemDetails.music_sound_bpm,
          music_fileType:itemDetails.music_fileType,
          music_size:itemDetails.music_size,
          licence_type:itemDetails.licence_type,
          product_thumbnail:itemDetails.product_thumbnail,
          product_main_type:itemDetails?.product_main_type,
          is_premium:itemDetails?.is_premium && itemDetails?.is_premium != "" ? itemDetails?.is_premium : 0,
          product_web: itemDetails?.product_web,
          invalid:false,


          // video_details: {
          //   duration: 'Duration: ' + itemDetails[0].clip_data.versions[0].ti,
          //   frame_rate: 'Frame Rate: ' + itemDetails[0].clip_data.pim_aut_fps,
          //   location: 'Location: ' + itemDetails[0].clip_data.pic_location_country,
          //   license: 'License: ' + (itemDetails[0].clip_data.pisc_single_use_license === null ? 'No' : 'Yes'),
          //   uses: 'Uses: ' + (itemDetails[0].clip_data.ed ? 'Editorial' : 'Commercial'),
          //   model_release: 'Model Release: ' + (itemDetails[0].clip_data.modelreleased ? 'Yes' : 'No'),
          //   prop_release: 'Property Release: ' + (itemDetails[0].clip_data.propertyreleased ? 'Yes' : 'No'),
          //   // av_bit_rate: itemDetails[0].clip_data.,
          //   // bit_depth: itemDetails[0].clip_data.,
          //   looping: 'Looping: ' + (itemDetails[0].clip_data.pic_keywords_cb.includes('KCA0700') ? 'yes' : 'no'),
          //   alpha_matte: 'Alpha Matte: ' + (itemDetails[0].clip_data.pic_keywords_cb.includes('vidmatte') ? 'yes' : 'no'),
          //   alpha_channel: 'Alpha Channel: ' + (itemDetails[0].clip_data.pic_keywords_cb.includes('KCT0200') ? 'yes' : 'no')
          // },
          downloadForTry: {
            link: '',
            name: itemDetails.product_main_image
          },
          cartInfo: {
            product: {
              product_info: {
                0: {
                  flv_base: itemDetails.product_thumbnail,
                  clip_data: {
                    pic_objectid: itemDetails.product_id,
                    pic_description: itemDetails.product_description,
                    n: itemDetails.product_title,
                  }
                },
                1: itemDetails.product_thumbnail,
                2: itemDetails.product_thumbnail,
                selected_product: {

                },
                media: {
                  id: itemDetails.api_product_id,
                },
              },
              type: 4
            }
          }
        }
      }

    }
    return itemDetailsValidated

  }

  getDownloadForTryFootageLink(footageId): Observable<any> {
    const url = 'sampledownloadFootage';
    const body = { productID: footageId };
    return this.safeRequest.sendRequest('post', url, body);
  }

  getItemRelated(keywords, searchType, authorName = '') {
    const url = 'get-author-products';
    const body = {
      type: searchType,
      limit: 10,
      search: authorName,
      pagenumber: 1,
    };
    return this.safeRequest.sendRequest('post', url, body);
  }

  getAlreadyDownloadedImage(user_id, media_id) {
    const url = 'get-already-downloaded-image';
    const body = { user_id: user_id, media_id: media_id };
    return this.safeRequest.sendRequest('post', url, body);
  }

  getItemRelatedSearchImage(mediaId, searchType, authorName = '') {
    const url = 'get-author-products';
    const body = {
      type: searchType,
      limit: 10,
      search: authorName,
      pagenumber: 1,
    };
    return this.safeRequest.sendRequest('post', url, body);
  }

  getItemRelatedSearchMusic(mediaId, searchType, authorName = '') {
    const url = 'get-author-musics';
    const body = {
      type: searchType,
      limit: 10,
      search: authorName,
      pagenumber: 1,
    };
    return this.safeRequest.sendRequest('post', url, body);
  }

  getRelatedProducts(type, product_id = '', product_keywords = '') {
    const url = 'get-category-products'
    const body = { type: type, product_id: product_id, search: product_keywords }
    return this.safeRequest.sendRequest('post', url, body)
  }

  getHomePageProducts() {
    const url = 'get-home-products'
    return this.safeRequest.sendRequest('get', url)
  }

  getRelatedMusics(product_id = '') {
    const url = 'get-category-musics'
    const body = { product_id: product_id }
    return this.safeRequest.sendRequest('post', url, body)
  }

  getLicenceDetails(type='') {
    const url = 'licence_details?type='+type

    return this.safeRequest.sendRequest('get', url)
  }

}
