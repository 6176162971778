import { Component, ElementRef, HostListener, OnInit,ViewEncapsulation,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { messages, music } from 'src/appv2/appv2.constants';
import { FeatureIncreaseCartCountAction } from 'src/appv2/reducers/features/features.actions';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { CartService } from 'src/appv2/services/cart.service';
import { DownloadService } from 'src/appv2/services/download.service';
import { ItemDetailsService } from 'src/appv2/services/item-details.service';
import { SearchService } from 'src/appv2/services/search.service';
import { ToastService } from 'src/appv2/services/toast.service';
import { WishlistService } from 'src/appv2/services/wishlist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'appv2-music-details',
  templateUrl: './music-details.component.html',
  styleUrls: ['./music-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MusicDetailsComponent implements OnInit {
  @ViewChild('audioPlayer') audioPlayer: any;


  /**TODO : keep Same Author section hidden for now. Need to improve in future */
  isSameAuthorSectionShow: boolean = false;
  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private mysearch: SearchService,
    private store$: Store<any>,
    private wishlist: WishlistService,
    private toastService: ToastService,
    private cartActions: CartService,
    private itemDetails: ItemDetailsService,
    private userData: AccountService,
    private download: DownloadService,
    private el:ElementRef
  ) { }

  itemData;
  isExtended = false;
  isStandard = false;
  productId: any = '';
  currPage: number = 1;
  musicData: any =[];
  musicKeywords: Array<any>;
  similarTracks: Array<any>;
  similarTracksKeyword: any = '';
  similarTracksAuthorKeyword: any = '';
  similarTracksAuthors: Array<any>;
  isPlaying: boolean = false;
  currentIndex: number;
  audioObj = new Audio();
  wishlistData = [];
  showAddToWishListModal: boolean = false;
  productMainType: string = '';
  productWeb: string;
  apiProductId: string;
  private baseUrl = environment.url;
  showCartPopup: boolean = false;
  getAlreadyDownloadedMusicFlag: boolean = false;
  loadingStatus: boolean
  emptyUserPlansList = true
  userSubscriptionPlans: Array<object> = []
  userLogined: boolean = false
  plansChecked: boolean = false
  ifUserHaveCreditedBalance: boolean = false;
  showUserPlans: boolean = false
  creditedPackageId: number;
  addedToCartData: any;
  AddToCartForm: FormGroup
  userChoosedItem: boolean = false
  isShowCartMessage = false
  prevApiProductId: string
  prevRequestData
  requestData
  extendedInfo: boolean = false;
  standardInfo: boolean = false;

  tiltop: object = {
    tiltopClass: '',
    message: ''
  }

  user;
  userWishlistedData: string[] = [];
  public displayDownload : Boolean =false;
  public filterVersion: any = [];
  selectedValue:string = ''
  public displayCart : Boolean =true;
  public licenceData:any
  public licenceCheck :number= 0
  public licenceArray  = []
  public selectedLicense:any
  public getAlreadyDownloadedImageData :any;
  public userSamePlans : number = 0
  public planId:number
  public selectedPlanLicence = []
  public invalidData : Boolean = false
  public displayMusicPlayer:boolean =false
  public musicPlayerData:any
  public slugTrack:string=''
  isFullTitleDisplayed = false;
  truncatedTitle: string;


  ngOnInit(): void {
    this.activatedRouter.params.subscribe(params => {
      const slug = params['slug'];
      if (slug) {
        this.productId = slug
        // Do something with the slug when the route parameter changes
      }
    });
    this.AddToCartForm = new FormGroup({
      choosenItem: new FormControl(false, [Validators.required]),
      choosenLicense: new FormControl('1', [Validators.required]),
    });
    this.productId = this.activatedRouter.snapshot.paramMap.get('slug');
    this.productMainType = 'music'
    this.licenceCheck = 0
    this.getMusic()
    this.user = localStorage.getItem('user_data');
    if (this.user) {
      this.userWishlistData();
    }
    this.licenceDetails()
  }

  ngDoCheck() {
    if (this.productId != this.prevApiProductId) {
      this.prevApiProductId = this.productId;
      this.getMusic();
    } else if (
      this.prevRequestData != this.requestData &&
      this.requestData != undefined
    ) {
      //this.getItemFromWishlist();
    }
    if (this.AddToCartForm != undefined) {
      this.userChoosedItem = this.AddToCartForm.value['choosenItem']
    }
    this.userLogined = (localStorage.getItem('user_data') != null)
      this.emptyUserPlansList = (this.userSubscriptionPlans.length == 0)
      if (this.userLogined && !this.plansChecked && this.musicData) {
        this.getUserInfo()
      }
  }

  // get user info
  getUserInfo() {
    this.userSubscriptionPlans = []
    this.userData.getUserInformation(JSON.parse(localStorage.getItem('user_data'))['Utype']).subscribe(data => {
      let userInfoData = data['data']['plans'];
      for (let i = 0; i < userInfoData.length; i++) {
        if (userInfoData[i]['package_type'].toLowerCase() == this.productMainType.toLowerCase()) {
          if ( userInfoData[i]['downloaded_product'] < userInfoData[i]['package_products_count']) {
              this.planId = userInfoData[i]['id']

            let licence_name = userInfoData[i]['licence']['slug']
              let filterV = music[licence_name]

               this.filterData(filterV)

              if (this.filterVersion.length > 0) {
                let getVersion = this.filterVersion[this.filterVersion.length-1];
                this.selectedValue = getVersion.name;

                let vl = JSON.stringify(getVersion, null, 2).replace(
                  /(\r\n|\n|\r)/gm,
                  ''
                );
                this.AddToCartForm.controls['choosenItem'].patchValue(vl);
                this.AddToCartForm.controls['choosenLicense'].patchValue(userInfoData[i]['licence']['id'])
                if(this.licenceData){
                  const indexOfObject = this.licenceData.findIndex(item => item.id == this.AddToCartForm.value['choosenLicense']);
                  this.licenceCheck = indexOfObject
                }
              }
              this.displayDownload = true

              if(this.musicData.is_premium =='1'){
                this.displayCart =true
              } else{
                this.displayCart = false
              }
              this.userSubscriptionPlans.push(userInfoData[i])
          }


        }
      }
      const versionSet = new Set();
      if(this.userSubscriptionPlans.length > 0){
        this.userSubscriptionPlans.forEach((obj:any) => {
          let version = obj.footage_tier
           // If the version is already in the set, it's a duplicate
           if (versionSet.has(version)) {
            this.selectedPlanLicence.push(version.toString());
           } else {
             // If version is not in the set, add it
             versionSet.add(version);
           }
         });
      }
      if(this.selectedPlanLicence.length > 0){
        this.userSamePlans = 2
      }
      this.emptyUserPlansList = (this.userSubscriptionPlans.length == 0)
    })
    this.plansChecked = true
  }

  onClickKeyword(item) {
    const trimmedItem = item.trim();
    this.router.navigate(['/musics'], {
      queryParams: { search: trimmedItem }});
  }

  userWishlistData = () => {
    let postData = JSON.parse(this.user);
    this.wishlist.getUserWishlist(postData).subscribe(
      (res) => {
        this.userWishlistedData = res.data.map((item) =>
          String(item.product_thumbnail)
        );
      },
      (error) => {
         console.log('error : ', error);
      }
    );
  };

  openSelectedPlans() {
    this.showUserPlans = false;
    if(this.userSamePlans > 1 && this.musicData.is_premium == 0 && this.selectedPlanLicence.includes(this.AddToCartForm.value['choosenLicense'].toString())){
        this.showUserPlans = true;
      }
      else if(this.musicData.is_premium == 1){
        this.toastService.warning(messages.footagePremiumProduct)
      } else{
        this.onSubmit(true,this.planId)
      }
  }

  addMusicToCart(musicItemData)
  {
    this.productMainType = musicItemData['product_main_type'];
    this.productWeb = musicItemData['product_web'];
    this.apiProductId = musicItemData['api_product_id'];
    this.getItemInfo('');
  }

  // Add to cart and downloading via subscription
  onSubmit(download = false, planId = undefined) {
    let addToCartData = this.musicData.cartInfo
    if (this.userLogined) {
      addToCartData['product']['token'] = localStorage.getItem('user_data')
      if (addToCartData['product'].type == 2) {
        addToCartData['product']['selected_product'] = JSON.parse(this.AddToCartForm.value['choosenItem'])
        addToCartData['product']['total'] = JSON.parse(this.AddToCartForm.value['choosenItem'])['price']
        this.addedToCartData = addToCartData['product']['total']
      } else if (addToCartData['product'].type == 4) {
        addToCartData['product']['product_info']['selected_product'] = this.AddToCartForm.value['choosenItem']
        addToCartData['product']['extended'] = this.AddToCartForm.value['choosenLicense']
        addToCartData['product']['selected_product'] = JSON.parse(this.AddToCartForm.value['choosenItem'])
        addToCartData['product']['total'] = JSON.parse(this.AddToCartForm.value['choosenItem'])['pr']
        this.addedToCartData = addToCartData['product']['total']
      }

      if (this.userChoosedItem) {

      if (download) {
        this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.downloadAddLoading}))
        addToCartData['product']['package'] = planId
      this.download.downloadWithSubscriptionPlan(addToCartData).subscribe(data => {
        this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))

        this.showUserPlans = false;
        if (data.data['url']) {
            window.location.href = data.data['url']
            this.getAlreadyDownloadedMusicFlag = true;
            this.toastService.success(data.message)
        } else {
          this.toastService.warning(data.message)
        }
      })
          } else {
            this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.cartAddLoading}))
            setTimeout(() => {
              this.tiltop['message'] = ''
            }, 100);
            this.tiltop['tiltopClass'] = ''
            this.cartActions.addToCart(addToCartData).subscribe(data => {
              this.store$.dispatch(
                new loadingChangeAction(<LoadingState>{ loadingStatus: false })
              );

              if (data.status == '1') {
                this.store$.dispatch(new FeatureIncreaseCartCountAction());
                this.showCartPopup = true;
              }

              if (data.status == '0') {
                this.showCartPopup = false;
                this.toastService.success(data.message);
              }
              setTimeout(() => {
                this.showCartPopup = false;
              }, 10000);
            })
          }
        } else {
          setTimeout(() => {
            this.toastService.warning(messages.chooseSize)
            this.tiltop['tiltopClass'] = 'error'
          }, 100);
          this.tiltop['message'] = ''
        }
      } else {
        this.showCartMessage()
      }
    }


  // Message if user didn't choose size
  showCartMessage() {
    this.isShowCartMessage = true
    setTimeout(() => {
      this.isShowCartMessage = false
    }, 2000);
  }

  handleUserPlanModalEvent = (event) => {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: true, loadingMessage: messages.musicDetailLoading}))
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showUserPlans = false;
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
    } else {
      this.onSubmit(true, event)
    }
  }

  getMusic() {
    this.licenceArray = [];
    this.getAlreadyDownloadedMusicFlag = false
    this.displayDownload = false
    this.displayCart = true
    this.plansChecked = false
    this.invalidData = false
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.musicDetailLoading  })
    );
    this.itemDetails
      .getItemDetail(this.productId, 'Music',3)
      .then((data) => {
        this.musicData = data;
        if(this.musicData && this.musicData.invalid == true){
          this.invalidData = true
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );
        } else{


          if (this.musicData.length <= 0) {
            this.toastService.warning('Not found any music record');
            return;
          }

          // if(this.musicData.is_premium == 0){
          //   let filterV = music.standard
          //   this.filterVersion = filterV.filter(item=>item.is_premium == 0)
          // }else{
          //   let filterV = music.standard
          //   this.filterVersion = filterV.filter(item=>item.is_premium == 1)
          // }

          this.filterVersion = this.musicData.versions;

          if (this.filterVersion.length > 0) {
            let getVersion = this.filterVersion[this.filterVersion.length-1];
            this.selectedValue = getVersion.name;

            let vl = JSON.stringify(getVersion, null, 2).replace(
              /(\r\n|\n|\r)/gm,
              ''
            );
            this.AddToCartForm.controls['choosenItem'].patchValue(vl);
          }

          if (this.user) {
            this.getAlreadyDownloadedMusic(
              JSON.parse(this.user)['Utype'],
              this.musicData.api_product_id
            );
          }

          this.musicKeywords = this.musicData['product_keywords'].split(',');
          this.productMainType = this.musicData['product_main_type']
          this.productWeb = this.musicData['product_web']
          this.apiProductId = this.musicData['api_product_id']

          this.similarTracksKeyword = this.musicKeywords[0]
          this.getSimilarTracks(this.musicData.product_id)
          this.truncateTitle()

          this.similarTracksAuthorKeyword = this.musicData?.author_username
          this.getSimilarTracksAuthors()
          this.store$.dispatch(
            new loadingChangeAction(<LoadingState>{ loadingStatus: false })
          );

        }
      });

  }

  getSimilarTracks(product_id) {
    this.itemDetails.getRelatedMusics(product_id)
      .subscribe((data) => {
        this.similarTracks = [];
        this.similarTracks = data.imgfootage;

        this.store$.dispatch(
          new loadingChangeAction(<LoadingState>{ loadingStatus: false })
        );
      });
  }

  getSimilarTracksAuthors() {
    this.itemDetails
      .getItemRelatedSearchMusic(
        '',
        'Music',
        'artist:'+this.musicData?.author_username
      )
      .subscribe((data) => {

        this.similarTracksAuthors = data.imgfootage;
        if (data.total > 0) {
          this.isSameAuthorSectionShow = true;
        }
        this.loadingStatus = false;
      });
  }

  redirectToDetailsPage(slug:string): void {
    this.router.navigate(['/music', slug]);
  }

  handleClick($index,slug,event='startEvent')
  {
    if($index !== -1){
      this.audioPlayer.nativeElement.pause();
    }
    if(event == 'startEvent'){
      if(this.currentIndex != $index){
        this.isPlaying = true
      }else{
        this.isPlaying = !this.isPlaying
      }
      this.currentIndex = $index
      this.displayMusicPlayer = true
      this.slugTrack = slug
      if(slug == 'similar'){
        this.musicPlayerData = this.similarTracks
      }else{
        this.musicPlayerData = this.similarTracksAuthors
      }
    }else {
      this.isPlaying = !this.isPlaying
      this.currentIndex = $index
    }

  }


  play() {
    if(this.isPlaying){
      this.handleClick(-1,this.slugTrack,'closeEvent')
    }

  }

  pause() {
    console.log("pause");
  }

  showModal(wishlist) {
    this.wishlist.getWishlistData(wishlist).subscribe(
      (res) => {
        if (res['status'] == 'success') {
          // Reload user wishlists and show success message
          this.wishlistData['product_id'] = res.data.product_id;
          this.wishlistData['type'] = res.data.product_main_type;
          this.wishlistData['title'] = res.data.product_title;
          this.wishlistData['itemPoster'] = res.data.product_thumbnail;
          this.wishlistData['author_username'] = res.data.product_owner;
          this.showAddToWishListModal = true;
        } else {
          this.showAddToWishListModal = false;
          this.toastService.error(messages.categoryListProductValidation);
        }
      },
      (error) => {
        // Handle API request error
         console.log('error : ', error);
      }
    );
  }

  downloadMusic(musicItemData,index) {
    if(musicItemData.is_premium ==1){
      this.toastService.warning(messages.footagePremiumProduct)
      return;
    }
    let userData = JSON.parse(this.user)

    this.getDownloadedMusic(userData['Utype'], musicItemData['product_id']).subscribe((data) => {
      // Handle the response from the getAlreadyDownloadedMusic API call
      this.productMainType = musicItemData['product_main_type'];
      this.productWeb = musicItemData['product_web'];
      this.apiProductId = musicItemData['api_product_id'];
      if(!data.status){
        this.getItemInfo(musicItemData);
      }else{
        this.redownloadProduct(musicItemData)
      }
    });
  }

  // Get item info
  async getItemInfo(musicItemData) {

    let downloadData =  {
      product: {
        product_info: {
          0: {
            flv_base: musicItemData.product_thumbnail,
            clip_data: {
              pic_objectid: musicItemData.product_id,
              pic_description: musicItemData.product_description,
              n: musicItemData.product_title,
            }
          },
          1: musicItemData.product_thumbnail,
          2: musicItemData.product_thumbnail,
          selected_product: {

          },
          media: {
            id: musicItemData.api_product_id,
          },
        },
        type: 4
      }
    }
    let addToCartData = downloadData
    let versions = musicItemData.options;
    let getVersion = musicItemData.options[versions.length-1]
    this.selectedValue = getVersion.name;

    let vl = JSON.stringify(getVersion, null, 2).replace(
      /(\r\n|\n|\r)/gm,
      ''
    );

    if (this.userLogined) {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.downloadAddLoading })
      );
      addToCartData['product']['token'] = localStorage.getItem('user_data')
      if (addToCartData['product'].type == 2) {
        addToCartData['product']['selected_product'] = JSON.parse(vl)
        addToCartData['product']['total'] = JSON.parse(vl)['price']
        this.addedToCartData = addToCartData['product']['total']
      } else if (addToCartData['product'].type == 3) {
        addToCartData['product']['product_info']['selected_product'] = vl
        addToCartData['product']['extended'] = this.AddToCartForm.value['choosenLicense']
        addToCartData['product']['selected_product'] = JSON.parse(vl)
        addToCartData['product']['total'] = JSON.parse(vl)['pr']
        this.addedToCartData = addToCartData['product']['total']
      }
      if(this.itemData.versions.length > 0){
        addToCartData['product']['version_data']= this.itemData.versions;
      }else{
        addToCartData['product']['version_data'] = []
      }
      addToCartData['product']['package'] = this.creditedPackageId
      this.download.downloadWithSubscriptionPlan(addToCartData).subscribe(data => {
        this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: false }))

        this.showUserPlans = false;
        if (data.status == 'success') {
          this.toastService.success(data.message)
        this.getAlreadyDownloadedMusicFlag = true;
          window.location.href = data.data['url']
        } else {
          this.toastService.warning(data.message)
        }
      })
    }else{
      this.toastService.warning(messages.musicDownloadSigninMust)
    }
  }

  /**
   * Gets already downloaded Music
   * @param userId
   * @param mediaId
   */
  getAlreadyDownloadedMusic(userId, mediaId)  {
   // Check already music downloaded
    this.itemDetails.getAlreadyDownloadedImage(userId, mediaId).subscribe((data) => {
      this.getAlreadyDownloadedImageData = data.data;
        if(this.getAlreadyDownloadedImageData.length > 0){
          this.licenceArray = this.getAlreadyDownloadedImageData.map(item => item.licence_type);
          if(this.licenceArray.includes(this.AddToCartForm.value['choosenLicense'].toString()) && this.displayDownload){
            this.getAlreadyDownloadedMusicFlag = true
          }else{
            this.getAlreadyDownloadedMusicFlag = false
          }
        }
      this.loadingStatus = false
    })
  }

  /**
   * Handles events triggered from the Add To Wishlist Modal.
   * If the event indicates the need to close the modal, the flag to show the modal is set to false.
   * @param {object} event - The event object containing information about the modal action.
   */
  handleWishlistModalEvent = (event) => {
    if (event['close_modal']) {
      // Set the flag to false, indicating that the Add To Wishlist Modal should be closed
      this.showAddToWishListModal = false;
      if(this.user){
        this.userWishlistData()
      }

    }
  };

  changePrice(e, i) {
    if (this.filterVersion.length > 1) {
      let getVersion = this.filterVersion[i];
      this.selectedValue = getVersion.name;
      let vl = JSON.stringify(getVersion, null, 2).replace(
        /(\r\n|\n|\r)/gm,
        ''
      );
      this.AddToCartForm.controls['choosenItem'].patchValue(vl);
    }
  }

  public hideShowExtended(name,id) {
    this.isExtended = !this.isExtended;
    this.isStandard = false;
    if (name == 'Standard') {
      let filterV = music.standard
      this.filterData(filterV)
    }else{
      let filterV = music.extended
      this.filterData(filterV)
    }
    this.AddToCartForm.controls['choosenLicense'].patchValue(id)
    if(this.userSubscriptionPlans){
      let licenceData =  this.userSubscriptionPlans.filter((item : any)=>item.footage_tier == id)
      if(licenceData.length ==0){
          this.displayCart = true
          this.displayDownload = false
      }else{
        if(this.musicData.is_premium == '1'){
          this.displayCart =true
        } else{
          this.displayCart = false
        }
        this.displayDownload = true
        this.planId = licenceData[0]['id']
      }
    }

    if(this.licenceArray.includes(id.toString()) && this.displayDownload){
      this.getAlreadyDownloadedMusicFlag = true
    }else{
      this.getAlreadyDownloadedMusicFlag = false
    }
  }

  public hideShowStandard() {
    this.isExtended = false;
    this.isStandard = !this.isStandard;
    if(this.musicData.is_premium == 0){
      let filterV = music.standard
      this.filterVersion = filterV.filter(item=>item.is_premium == 0)
      this.AddToCartForm.controls['choosenItem'].patchValue(JSON.stringify(this.filterVersion[0]));
    }else{
      let filterV = music.standard
      this.filterVersion = filterV.filter(item=>item.is_premium == 1)
      this.AddToCartForm.controls['choosenItem'].patchValue(JSON.stringify(this.filterVersion[0]));
    }

  }

  public getDownloadedMusic(user_id,product_id): Observable<any>{
    return this.itemDetails.getAlreadyDownloadedImage(user_id, product_id);

  }

  public redownloadProduct(musicItemData){
    this.store$.dispatch(
      new loadingChangeAction(<LoadingState>{ loadingStatus: true,loadingMessage:messages.redownloadLoading })
    );
   let payload
    if(musicItemData && musicItemData !== '' && musicItemData != undefined){
       payload = {
        id_media: musicItemData.api_product_id,
        product_id: musicItemData.product_id,
        user_id: JSON.parse(this.user)['Utype'],
        type: 4,
      };

    }else{
      payload = {
        id_media: this.musicData['mediaId'],
        product_id: this.musicData.product_id,
        user_id: JSON.parse(this.user)['Utype'],
        type: this.musicData.cartInfo.product.type,
      };
    }
    this.download.reDownloadImage(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if (data.status == 'success') {
        this.toastService.success(data.message);
        window.location.href = data.data['download_url'];
      } else {
        this.toastService.warning(data.message);
      }
    });
  }
  public hideShowExtendedInfo(licence) {
    this.selectedLicense = licence
    this.extendedInfo =!this.extendedInfo
  }


  @HostListener('document:click',['$event','$event.target'])

  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.el.nativeElement.contains(targetElement);
    if((this.extendedInfo && targetElement.className !== 'extended-img') || !this.extendedInfo ){
      this.extendedInfo = false
    }
    if((this.standardInfo && targetElement.className !== 'standard-img') || !this.standardInfo){
      this.standardInfo = false
    }

    let classNameList = ['previous','playon','pause','next','name','range','time','volume-full','mute','heart-null','heart-fill','cart','close-icon','play-btn']
    if(!classNameList.includes(targetElement.className)){
      if(this.displayMusicPlayer){
        this.handleClick(-1,this.slugTrack,'CloseEvent')
      }

    }
  }

  public filterData(filterV){

    if(this.musicData.is_premium == 0){
      this.filterVersion = filterV.filter(item=>item.is_premium == 0)
      this.AddToCartForm.controls['choosenItem'].patchValue(JSON.stringify(this.filterVersion[0]));
    }else{
      this.filterVersion = filterV.filter(item=>item.is_premium == 1)
      this.AddToCartForm.controls['choosenItem'].patchValue(JSON.stringify(this.filterVersion[0]));
    }

  }

  public licenceDetails(){
    const payload = '3'
    this.itemDetails.getLicenceDetails(payload).subscribe((data) => {
      this.store$.dispatch(
        new loadingChangeAction(<LoadingState>{ loadingStatus: false })
      );
      if(data.status == 'success'){
        this.licenceData = data.data
        this.licenceData = this.licenceData.filter(item => item.slug !== 'digital');
        if(this.userSubscriptionPlans.length == 0){
          this.AddToCartForm.controls['choosenLicense'].patchValue(this.licenceData[0].id);
        }

        const indexOfObject = this.licenceData.findIndex(item => item.id == this.AddToCartForm.value['choosenLicense']);
        this.licenceCheck = indexOfObject

        if(this.licenceArray.includes(this.AddToCartForm.value['choosenLicense'].toString()) && this.displayDownload){
          this.getAlreadyDownloadedMusicFlag = true
        }else{
          this.getAlreadyDownloadedMusicFlag = false
        }
      }else{
        this.licenceData = []
      }


    });
  }

  public handleClickPlay(event){
    this.isPlaying = event['playing']
    this.currentIndex = event['index']
    if(event['hidePlayer']){
      this.displayMusicPlayer = false
    }
    if(event['wishlist'] && !event['hidePlayer']){
      let wishlist = ''
      if(this.slugTrack == 'similar'){
        wishlist = this.similarTracks[this.currentIndex]
      }else{
       wishlist =  this.similarTracksAuthors[this.currentIndex]
      }

      this.showModal(wishlist)

    }

  }

  toggleTitle(): void {
    this.isFullTitleDisplayed = !this.isFullTitleDisplayed;
  }

   truncateTitle(): void {
    if (this.musicData.product_title.length > 60) {
      this.truncatedTitle = this.musicData?.product_title.substring(0, 60);
    } else {
      this.truncatedTitle = this.musicData?.product_title;
    }
  }
}
